import React from 'react';
import styles from './header.module.css';
import { Link, useNavigate } from "react-router-dom";
import { authData } from './getAuth';
import { Button } from '@mui/material';

function Header1() {
    const navigate = useNavigate();
    const auth = authData();

    const logout = () => {
        localStorage.removeItem('authDataAceNew');
        navigate('/login');
    }

    return (<div className={`${styles.Header}`}>
        <div className={`${styles.Container}`}>
            <div className={`${styles.HeadRow}`}>
                <div className={`${styles.HeadLogo}`}></div>
                <div className={`${styles.Menu}`}>
                    <ul>
                        <li><Link to={'/mentor'}>Home</Link></li>
                        <li><Link to={'/'}>Explore</Link></li>
                        <li><Link to={'/'}>For Mentors</Link></li>
                        <li><Link to={'/'}>About Us</Link></li>
                    </ul>
                    <Link className={`${styles.HeadLoginBU}`} to={'/mentor/dashboard'}>{auth?.first_name}</Link>
                    <Button onClick={logout}>Logout</Button>
                </div>
            </div>
        </div>
    </div>);
}

export default Header1;