import React, { useEffect } from 'react';
import styles from './joinquiz.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import { authData } from '../../components/getAuth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useForm } from 'react-hook-form';
import Header2 from '../../components/header2';

const JoinQuiz = () => {
    const auth = authData();
    const { id } = useParams();
    const navigate = useNavigate();

    const { handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/check-quiz/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
            setTimeout(() => {
                navigate('/student/test-list');
            }, 2000);
        });
    }, [auth?.token, id, navigate]);

    const onSubmit = (data) => {
        axios.post(process.env.REACT_APP_API_URL + 'student/join-quiz/' + id, data, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            navigate('/student/quiz/' + id);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }

    return (<React.Fragment>
        <Header2 />
        <div className={`${styles.login_MainDiv}`}>
            <div className={`${styles.login_BannerSec}`}></div>
            <div className={`${styles.login_FormSec}`}>
                <div className={`${styles.login_LohinForm}`}>
                    <h2 className={`${styles.login_FormTitle}`}>Join Quiz</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${styles.login_FormRow}`}>
                            <div className={`${styles.login_FormFieldHalf}`}>
                                <span className={`${styles.details}`}>Quiz Code</span>
                                <input type="text" name="text" className={`${styles.login_FormControl}`} placeholder="Quiz Code" {...register("quiz_code", { required: "This field is required" })} />
                                {errors?.quiz_code && <p className={`${styles.login_ErrorM}`}>{errors?.quiz_code?.message}</p>}
                            </div>
                            <div className={`${styles.login_FormFieldHalf}`}>
                                <span className={`${styles.details}`}>Quiz Password</span>
                                <input type="password" className={`${styles.login_FormControl}`} placeholder="Quiz Password" {...register("quiz_password", { required: "This field is required" })} />
                                {errors?.quiz_password && <p className={`${styles.login_ErrorM}`}>{errors?.quiz_password?.message}</p>}
                            </div>
                            <div className={`${styles.buttonGroup}`}>
                                <input type="submit" value="Join quiz" className={`${styles.loginButton}`} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(JoinQuiz);