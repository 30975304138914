import React, { useEffect, useState } from 'react';
import styles from './myprofile.module.css';
import Header2 from '../../components/header2';
import withStudentAuth from '../../components/withStudentAuth';
import { Link } from 'react-router-dom';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';

const MyProfile = () => {
  const auth = authData();
  const [myProfile, setMyProfile] = useState({});

  useEffect(() => {
    function getMyProfile() {
      axios(process.env.REACT_APP_API_URL + 'student/my-profile', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setMyProfile(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getMyProfile();
    }
  }, [auth?.token]);

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.create_AccomplishmentSec}`}>
          <p>My Profile</p>
        </div>

        <div className={`${styles.general_FormBoxArea}`}>
          <div>
            <div>
              <p style={{ fontWeight: 600 }}>{myProfile?.first_name} {myProfile?.last_name}</p>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <p style={{ margin: '10px 0' }}><span style={{ fontWeight: 600 }}>Name:</span> {myProfile?.first_name} {myProfile?.last_name}</p>
              <p style={{ margin: '10px 0' }}><span style={{ fontWeight: 600 }}>Email:</span> {myProfile?.email}</p>
              <p style={{ margin: '1px 0' }}><span style={{ fontWeight: 600 }}>Phone No:</span> {myProfile?.phone}</p>
            </div>
            <div>
              <Link to={"/student/update-profile"} style={{ fontSize: '14px', lineHeight: 'normal', fontWeight: 600, textDecoration: 'none', color: '#fff', padding: '6px 15px', borderRadius: '4px', border: 'none', background: '#FF7A00', cursor: 'pointer', overflow: 'hidden', marginRight: '5px' }}>Edit Profile</Link>
              <Link to={"/student/change-password"} style={{ fontSize: '14px', lineHeight: 'normal', fontWeight: 600, textDecoration: 'none', color: '#fff', padding: '6px 15px', borderRadius: '4px', border: 'none', background: '#FF7A00', cursor: 'pointer', overflow: 'hidden' }}>Change Password</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withStudentAuth(MyProfile);