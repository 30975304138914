import React, { useState, useEffect } from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs, Button, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, Link, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";

function FAQAdd({ history, match }) {
  const auth = authData();
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  let schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
    description: yup.string().required('This field is Required'),
  });

  const { register, handleSubmit, watch, setValue, formState: { errors }, control } = useForm({
    resolver: yupResolver(schema), mode: 'onChange',
    defaultValues: {
      is_public: false
    }
  });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios.get(process.env.REACT_APP_API_URL + 'faqs/' + id, {
          headers: { 'x-access-token': auth?.token }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          setValue('title', itemData.title);
          setValue('description', itemData.description);
          setValue('is_public', itemData.is_public);
        }).catch(error => {
          setContentLoading(false);
        });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, id, setValue, contentLoading, auth?.token]);

  async function onSubmit(data) {
    data = { ...data, status: 1 };
    const postData = data;

    setLoading(true);

    if (isAddMode) {
      return axios.post(process.env.REACT_APP_API_URL + 'faqs', postData, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/faq/list');
      }).catch(error => {
        setLoading(false);
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    } else {
      return axios.put(process.env.REACT_APP_API_URL + 'faqs/' + id, postData, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/faq/list');
      }).catch(error => {
        setLoading(false);
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
  }

  return (<React.Fragment>
    {loading && <Loader />}
    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>}
    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>FAQ {isAddMode ? 'Add' : 'Edit'}</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={'/admin/faq/list'}>FAQs</Link>
          <Typography color="text.primary">{isAddMode ? 'Add' : 'Edit'}</Typography>
        </Breadcrumbs>
      </div>
      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Title*</label>
                <input {...register("title")} className={`${styles.FormControl}`} />
                {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Description</label>
                <textarea {...register("description")} className={`${styles.FormControlText}`} rows={10}></textarea>
                {errors.description && !watch().description && <span className={`${styles.ErrorM}`}>{errors.description.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <Controller
                  control={control}
                  {...register("is_public")}
                  render={({ field }) =>
                    <FormControlLabel {...field} control={<Checkbox defaultChecked={field.value} />} label={'Public'} />
                  }
                />
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/faq/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}


export default withAdminAuth(FAQAdd);