import React, { useEffect, useState, Fragment } from 'react';
import styles from './createquestionfrombank.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import DataTable from 'react-data-table-component';

const CreateTestStep3 = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { id } = useParams();
  const [testDet, setTestDet] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const columns = [
    {
      name: 'Question',
      selector: (row, index) => 'Question ' + (index + 1) + ': ' + row.title,
      sortable: false,
      width: '85%'
    },
    {
      name: 'Marks',
      selector: row => row.marks,
      sortable: false
    }
  ];

  useEffect(() => {
    function getTest() {
      axios(process.env.REACT_APP_API_URL + 'mentor/test/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setTestDet(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getTest();
    }
  }, [auth?.token, id]);

  useEffect(() => {
    function getQuestionList() {
      axios(process.env.REACT_APP_API_URL + 'mentor/all-questions/' + testDet?.subject_id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setQuestionList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token && testDet) {
      getQuestionList();
    }
  }, [auth?.token, testDet]);

  const finishTest = () => {
    let selectedRowsTemp = selectedRows.map(s => {
      delete s._id;
      delete s.image_url;
      let options = s?.options.map(op => {
        delete op.image_url;
        return op;
      });
      return { ...s, test_id: id, options: options };
    })
    axios.post(process.env.REACT_APP_API_URL + 'mentor/create-bulk-question', { questions: selectedRowsTemp }, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/create-test/step-3/' + id);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const ExpandedComponent = ({ data }) => {
    return <div className={`${styles.halfBig_Div}`}>
      <p className={`${styles.Big_DivHeading}`}>{data?.title}</p>
      {(data?.image_url) && <img src={data?.image_url} alt='' style={{ maxWidth: '100%', maxHeight: '300px' }} />}
      <div className={`${styles.borderSec}`}></div>
      <div>
        <FormControl>
          <RadioGroup>
            {data?.options?.map((opt, ind) => {
              let className = `${styles.ansLabel}`;
              if (opt?.is_correct) {
                className += ` ${styles.correctAnsLabel}`;
              }
              return (<>
                <FormControlLabel value={ind} control={<Radio />} checked={opt?.is_correct} label={opt?.title} key={ind} className={className} />
                {(opt?.image_url) && <img src={opt?.image_url} alt='' style={{ maxWidth: '200px', maxHeight: '100px', marginLeft: '30px' }} />}
                {(opt?.remark && opt?.remark !== '') && <div className={`${styles.remarkArea}`}>
                  <p className={`${styles.Headingremark}`}>Remark</p>
                  <p className={`${styles.subHeadingremark}`}>{opt?.remark}</p>
                </div>}
              </>);
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </div>;
  };

  return (<React.Fragment>

    <div className={`${styles.background_Color}`}>

      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.MainSec_Area}`}>
          <div>
            <div className={`${styles.BigDiv_Sec_UnderBg}`}>
              {(selectedRows.length > 0) && <div className={`${styles.Selected_Area}`}>{selectedRows.length} {selectedRows.length > 1 ? 'questions' : 'question'} seleted</div>}
              <DataTable
                className={`${styles.table}`}
                columns={columns}
                data={questionList}
                selectableRows
                onSelectedRowsChange={handleChange}
                pagination
                expandableRows
                expandableRowsComponent={ExpandedComponent}
              />
            </div>
          </div>
        </div>
        {(selectedRows.length > 0) && <div className={`${styles.ending_BUDiv}`}>
          <button type='button' className={`${styles.ending_BU}`} onClick={finishTest}>Add to test</button>
        </div>}
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(CreateTestStep3);