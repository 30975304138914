import React from 'react';
import styles from './signup.module.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { StorageSetItem } from '../../../admin/components/StorageComp';

const Signup = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    data = { ...data, status: 1 };
    const postData = data;
    return axios.post(process.env.REACT_APP_API_URL + 'signup/student', postData).then(response => {
      StorageSetItem("authDataAceNew", JSON.stringify(response.data.data), 24 * 60 * 60 * 1000);
      navigate('/student/sign-up-2');
    });
  }

  return (<React.Fragment>
    <div className={`${styles.login_MainDiv}`}>
      <div className={`${styles.login_BannerSec}`}>
        <img src='/static/img/sign_page_image.jpg' alt='' />
      </div>
      <div className={`${styles.login_FormSec}`}>
        <div className={`${styles.login_LohinForm}`}>
          <h2 className={`${styles.login_FormTitle} ${styles.Heading}`}>Signup as Student</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.login_FormRow}`}>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>First Name</span>
                <input type="text" {...register("first_name", { required: 'This field is required' })} className={`${styles.login_FormControl}`} placeholder="Enter First Name" />
                {errors?.first_name && <p className={`${styles.login_ErrorM}`}>{errors?.first_name?.message}</p>}
              </div>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Last Name</span>
                <input type="text" {...register("last_name", { required: 'This field is required' })} className={`${styles.login_FormControl}`} placeholder="Enter Last Name" />
                {errors?.last_name && <p className={`${styles.login_ErrorM}`}>{errors?.last_name?.message}</p>}
              </div>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Email ID</span>
                <input type="email" {...register("email", {
                  required: "This field is required",
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email address must be a valid address",
                  },
                })} className={`${styles.login_FormControl}`} placeholder="Email" />
                {errors?.email && <p className={`${styles.login_ErrorM}`}>{errors?.email?.message}</p>}
              </div>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Phone Number</span>
                <input type="text" {...register("phone", { required: 'This field is required' })} className={`${styles.login_FormControl}`} placeholder="Phone Number" />
                {errors?.phone && <p className={`${styles.login_ErrorM}`}>{errors?.phone?.message}</p>}
              </div>
              <div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Password</span>
                <input type="password" {...register("password", { required: 'This field is required' })} className={`${styles.login_FormControl}`} placeholder="Password" />
                {errors?.password && <p className={`${styles.login_ErrorM}`}>{errors?.password?.message}</p>}
              </div>
              {/*<div className={`${styles.login_FormFieldHalf}`}>
                <span className={`${styles.details}`}>Verify OTP</span>
                <label className={`${styles.login_FormLabel}`}>
                  <input type="number" name="number" id="number" className={`${styles.login_FormControl}`} placeholder="Phone Number" />
                </label>
              </div>
              <a href=""><p className={`${styles.Forgot_PasswordArea}`}>Resend OTP</p></a>*/}
              <div className={`${styles.buttonGroup}`}>
                <input type="submit" value="Create account" className={`${styles.loginButton}`} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default Signup;