import React, { useEffect, useRef, useState } from 'react';
import styles from './quizdetails.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import { authData } from '../../components/getAuth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, TextField } from '@mui/material';
import Header2 from '../../components/header2';

const QuizDetails = () => {
    const navigate = useNavigate();
    const auth = authData();
    const { id } = useParams();

    const [quizDetails, setQuizDetails] = useState({});
    const [questionList, setQuestionList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [selectedAnswers, setSelectedAnswers] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState(null);

    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');

    useEffect(() => {
        const quizOptions = localStorage.getItem("quizOptions");
        if (quizOptions) {
            let quizOptionsTemp = JSON.parse(quizOptions);
            questionList.forEach((iii, inn) => {
                if (typeof (quizOptionsTemp[inn]) === 'undefined') {
                    quizOptionsTemp[inn] = null;
                }
                if (inn === 0) {
                    setSelectedOption(quizOptionsTemp[inn]);
                }
            });
            setSelectedAnswers(quizOptionsTemp);
        } else {
            localStorage.setItem("quizOptions", JSON.stringify([]));
        }
    }, [questionList]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/quiz-details/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            let quizData = response.data.data;
            if (quizData.length > 0) {
                setQuizDetails(quizData[0]);
                if (!localStorage.getItem("quizTime")) {
                    localStorage.setItem("quizTime", parseInt(quizData[0].time_limit) * 60);
                }
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token, id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/question-list/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setQuestionList(response.data.data);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token, id]);

    useEffect(() => {
        setCurrentQuestion(questionList[currentIndex]);
    }, [currentIndex, questionList]);

    const nextQuestion = () => {
        setCurrentIndex(c => c + 1);
        setSelectedOption(selectedAnswers[currentIndex + 1]);
    }

    const cngIndex = (e, cIndex) => {
        setSelectedOption(selectedAnswers[cIndex]);
        setCurrentIndex(cIndex);
    }

    const cngRadio = (event) => {
        let selectedAnswersTemp = selectedAnswers;
        selectedAnswersTemp[currentIndex] = parseInt(event.target.value);
        setSelectedAnswers(selectedAnswersTemp);
        setSelectedOption(event.target.value);
        localStorage.setItem("quizOptions", JSON.stringify(selectedAnswersTemp));
    }

    const cngCheckbox = (event) => {
        let selectedAnswersTemp = selectedAnswers;
        if (selectedAnswersTemp[currentIndex] === null) {
            if (event.target.checked) {
                selectedAnswersTemp[currentIndex] = [parseInt(event.target.value)];
            }
        } else {
            let oldValues = selectedAnswersTemp[currentIndex];
            if (event.target.checked) {
                selectedAnswersTemp[currentIndex].push(parseInt(event.target.value));
            } else {
                const index = oldValues.indexOf(parseInt(event.target.value));
                if (index > -1) {
                    oldValues.splice(index, 1);
                }

                if (oldValues.length > 0) {
                    selectedAnswersTemp[currentIndex] = oldValues;
                } else {
                    selectedAnswersTemp[currentIndex] = null;
                }
            }
        }
        setSelectedAnswers(selectedAnswersTemp);
        localStorage.setItem("quizOptions", JSON.stringify(selectedAnswersTemp));
    }

    const cngText = (e) => {
        setSelectedAnswers(p => { return p.map((item, index) => index === currentIndex ? (e.target.value !== '' ? e.target.value : null) : item) });
        let selectedAnswersTemp = selectedAnswers;
        selectedAnswersTemp[currentIndex] = (e.target.value !== '' ? e.target.value : null);
        localStorage.setItem("quizOptions", JSON.stringify(selectedAnswersTemp));
    }

    const clearAnswer = () => {
        let selectedAnswersTemp = selectedAnswers;
        selectedAnswersTemp[currentIndex] = null;
        setSelectedAnswers(selectedAnswersTemp);
        setSelectedOption(null);
        localStorage.setItem("quizOptions", JSON.stringify(selectedAnswersTemp));
    }

    const finishQuiz = () => {
        let totalQuestions = 0;
        let totalAttemted = 0;
        let totalHardAttemted = 0;
        let totalMediumAttemted = 0;
        let totalEasyAttemted = 0;
        let totalCorrect = 0;
        let totalHardCorrected = 0;
        let totalMediumCorrected = 0;
        let totalEasyCorrected = 0;
        let totalMarks = 0;
        let totalEvaluate = 0;
        let answerList = questionList.map((item, index) => {
            totalQuestions++;
            if (selectedAnswers[index] !== null) {
                let correctAnswer = selectedAnswers[index];
                let answerObj = { answer: correctAnswer, is_correct: false, is_evalute: 0, marks: 0 };
                totalAttemted++;
                if (item.level === 'Easy') {
                    totalEasyAttemted++;
                }
                if (item.level === 'Medium') {
                    totalMediumAttemted++;
                }
                if (item.level === 'Hard') {
                    totalHardAttemted++;
                }
                if (item.type === 'Single Select' || item.type === 'True/False') {
                    totalEvaluate++;
                    answerObj = { ...answerObj, is_evalute: 1 };
                    if (item.options[correctAnswer].is_correct) {
                        totalCorrect++;
                        if (item.level === 'Easy') {
                            totalEasyCorrected++;
                        }
                        if (item.level === 'Medium') {
                            totalMediumCorrected++;
                        }
                        if (item.level === 'Hard') {
                            totalHardCorrected++;
                        }
                        totalMarks = totalMarks + parseInt(item.marks);
                        answerObj = { ...answerObj, is_correct: true, marks: parseInt(item.marks) };
                    }
                }
                if (item.type === 'Muliple Select') {
                    totalEvaluate++;
                    answerObj = { ...answerObj, is_evalute: 1 };
                    correctAnswer.sort();
                    let correctOptions = item.options?.map((item5, index5) => {
                        if (item5.is_correct) {
                            return index5;
                        }
                        return null;
                    });
                    correctOptions = correctOptions.filter(i => i !== null);
                    correctOptions.sort();
                    if (arrayEquals(correctAnswer, correctOptions)) {
                        totalCorrect++;
                        if (item.level === 'Easy') {
                            totalEasyCorrected++;
                        }
                        if (item.level === 'Medium') {
                            totalMediumCorrected++;
                        }
                        if (item.level === 'Hard') {
                            totalHardCorrected++;
                        }
                        totalMarks = totalMarks + parseInt(item.marks);
                        answerObj = { ...answerObj, is_correct: true, marks: parseInt(item.marks) };
                    }
                }
                return answerObj;
            }
            return null;
        });

        let quizTime = localStorage.getItem("quizTime");
        quizTime = parseInt(quizDetails?.time_limit) * 60 - parseInt(quizTime);
        if (quizTime < 0) {
            quizTime = parseInt(quizDetails?.time_limit) * 60;
        }

        axios.post(process.env.REACT_APP_API_URL + 'student/save-answers/' + id, { answers: answerList, total_questions: totalQuestions, total_attemted: totalAttemted, total_correct: totalCorrect, total_marks: totalMarks, total_quiz_marks: quizDetails?.total_marks, total_hard_attemted: totalHardAttemted, total_medium_attemted: totalMediumAttemted, total_easy_attemted: totalEasyAttemted, total_time: quizTime, total_hard_corrected: totalHardCorrected, total_medium_corrected: totalMediumCorrected, total_easy_corrected: totalEasyCorrected, total_evaluate: totalEvaluate, is_evalute: (totalAttemted === totalEvaluate) ? 1 : 0 }, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            navigate('/student/quiz-result/' + id);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    useEffect(() => {
        const getTimeRemaining = (e) => {
            const total = Date.parse(e) - Date.parse(new Date());
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / 1000 / 60 / 60) % 24);
            return { total, hours, minutes, seconds };
        }

        const startTimer = (e) => {
            let { total, hours, minutes, seconds } = getTimeRemaining(e);
            if (total >= 0) {
                setTimer(
                    (hours > 9 ? hours : '0' + hours) + ':' +
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            }
        }

        const clearTimer = (e) => {
            if (Ref.current) clearInterval(Ref.current);
            const id = setInterval(() => {
                let quizTime = localStorage.getItem("quizTime");
                localStorage.setItem("quizTime", quizTime - 1);
                startTimer(e);
            }, 1000)
            Ref.current = id;
        }

        const getDeadTime = () => {
            let quizTime = localStorage.getItem("quizTime");
            let deadline = new Date();
            deadline.setSeconds(deadline.getSeconds() + parseInt(quizTime));
            return deadline;
        }

        clearTimer(getDeadTime());
    }, [quizDetails]);

    return (<React.Fragment>
        <div className={`${styles.background_Color}`}>
            <Header2 />

            <div className={`${styles.Container}`}>
                <div className={`${styles.main_DivSec}`}>
                    <div className={`${styles.mainDivSec_Smalldiv}`}>
                        <div className={`${styles.textArea}`}>
                            <p className={`${styles.classHader}`}>{quizDetails?.class?.title}</p>
                            <p className={`${styles.titleForHeding}`}>{quizDetails?.topic ? quizDetails?.topic?.title : 'All Chapter'}</p>
                            <p className={`${styles.Subject}`}>{quizDetails?.subject?.title}</p>
                            <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                        </div>
                        <div className={`${styles.group___Ms007}`}>
                            <div className={`${styles.details_AreaClassSec}`}>
                                <p className={`${styles.ClassSecHeading}`}>Board : <span>{quizDetails?.board?.title}</span></p>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <img src="/static/img/Vector.svg" alt='' className={`${styles.massgLogo}`} />
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>No: of Questions</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.total_question}</p>
                                    </div>
                                </div>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <div className={`${styles.incosCK}`}>
                                        <i data-feather="check"></i>
                                    </div>
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>Total Marks</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.total_marks}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.mainDivSec_Bigdiv1}`}>
                        <div className={`${styles.leftSide_Div}`}>
                            <div className={`${styles.halfSmall_Div}`}>
                                <p className={`${styles.Question1Tex}`}>Question {currentIndex + 1}</p>
                                <div className={`${styles.halfSmall_DivGroup}`}>
                                    <p>Level:</p>
                                    <div className={`${styles.spanCus}`}>
                                        <i data-feather="menu"></i>
                                        <p>{currentQuestion?.level}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.halfBig_Div}`}>
                                <p>{currentQuestion?.title}</p>
                                <p>{currentQuestion?.description}</p>
                                {(currentQuestion?.image) && <img src={currentQuestion?.image} alt='' style={{ maxWidth: '100%', maxHeight: '300px' }} />}
                                <div className={`${styles.borderSec}`}></div>
                                <div>
                                    {currentQuestion?.type === 'Single Select' && <FormControl key={currentIndex}>
                                        <RadioGroup value={selectedOption} onChange={cngRadio}>
                                            {currentQuestion?.options?.map((opt, ind) => {
                                                return (<>
                                                    <FormControlLabel value={ind} control={<Radio />} label={opt?.title} key={ind} />
                                                    {(opt?.image) && <img src={opt?.image} alt='' style={{ maxWidth: '250px', maxHeight: '120px', marginLeft: '32px' }} />}
                                                </>);
                                            })}
                                        </RadioGroup>
                                    </FormControl>}
                                    {currentQuestion?.type === 'True/False' && <FormControl key={currentIndex}>
                                        <RadioGroup value={selectedOption} onChange={cngRadio}>
                                            {currentQuestion?.options?.map((opt, ind) => {
                                                return (<>
                                                    <FormControlLabel value={ind} control={<Radio />} label={opt?.title} key={ind} />
                                                    {(opt?.image) && <img src={opt?.image} alt='' style={{ maxWidth: '250px', maxHeight: '120px', marginLeft: '32px' }} />}
                                                </>);
                                            })}
                                        </RadioGroup>
                                    </FormControl>}
                                    {currentQuestion?.type === 'Muliple Select' && <FormControl key={currentIndex}>
                                        <FormGroup onChange={cngCheckbox}>
                                            {currentQuestion?.options?.map((opt, ind) => {
                                                return (<>
                                                    <FormControlLabel value={ind} control={<Checkbox checked={selectedAnswers[currentIndex] && Array.isArray(selectedAnswers[currentIndex]) ? (selectedAnswers[currentIndex]?.indexOf(ind) > -1 ? true : false) : false} />} label={opt?.title} key={ind} />
                                                    {(opt?.image) && <img src={opt?.image} alt='' style={{ maxWidth: '250px', maxHeight: '120px', marginLeft: '32px' }} />}
                                                </>);
                                            })}
                                        </FormGroup>
                                    </FormControl>}
                                    {currentQuestion?.type === 'Subjective' && <div style={{ 'width': '600px' }}>
                                        <p>Answer</p>
                                        <TextField id={"outlined-multiline-static-" + currentIndex} fullWidth multiline minRows={4} placeholder="Write your answer" value={selectedAnswers[currentIndex] ? selectedAnswers[currentIndex] : ''} onChange={cngText} />
                                    </div>}
                                </div>
                                <div className={`${styles.halfBig_DivBu_Group}`}>
                                    <button className={`${styles.halfBig_DivBu}`} type='button' onClick={clearAnswer}>CLEAR MY CHOICE</button>
                                    {((currentIndex + 1) < questionList.length) && <button className={`${styles.halfBig_DivBu}`} type='button' onClick={nextQuestion}>NEXT</button>}
                                    {((currentIndex + 1) >= questionList.length) && <button className={`${styles.halfBig_DivBu}`} type='button' onClick={finishQuiz}>FINISH</button>}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.leftSide_Div2}`}>
                            <div>
                                <div className={`${styles.timer_Heading}`}>Time left</div>
                                <div className={`${styles.timer}`}>{timer}</div>
                            </div>
                            <div>
                                <div className={`${styles.timer_Titel}`}>Quiz navigation</div>
                                <div className={`${styles.indexDiv}`}>
                                    {questionList.map((item, index) => {
                                        return <button type='button' className={((currentIndex === index) ? `${styles.indexSpan} ${styles.current}` : ((selectedAnswers[index] || selectedAnswers[index] === 0) ? `${styles.indexSpan} ${styles.attempted}` : `${styles.indexSpan}`))} key={index} onClick={(e) => cngIndex(e, index)}>{index + 1}</button>
                                    })}
                                </div>
                            </div>
                            <button className={`${styles.Time_leftBU}`} type='button' onClick={finishQuiz}>Finish Attempt</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(QuizDetails);