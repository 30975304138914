import { Navigate } from "react-router-dom";
import { StorageGetItem } from "./StorageComp";

const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const authDataAce = StorageGetItem("authDataAceNew");
      if (authDataAce) {
        let authDataAceArr = JSON.parse(authDataAce);
        if (authDataAceArr?.role === 'Admin') {
          return <WrappedComponent {...props} />;
        } else if (authDataAceArr?.role === 'Mentor') {
          return <Navigate to={'/mentor/dashboard'} />;
        } else if (authDataAceArr?.role === 'Student') {
          return <Navigate to={'/student/dashboard'} />;
        } else {
          return <Navigate to={'/home'} />;
        }
      } else {
        return <Navigate to={'/admin/login'} />
      }
    }
    return null;
  };
};

export default withAdminAuth;