import React from 'react';
import styles from './mytests.module.css';
import { useNavigate } from 'react-router-dom';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import withStudentAuth from '../../components/withStudentAuth';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-smart-toaster';
import * as moment from 'moment';
import { Stack, Pagination } from '@mui/material';

const MyTests = () => {
    const auth = authData();
    const navigate = useNavigate();
    const perPage = 10;
    const [testList1, setTestList1] = useState([]);
    const [testList2, setTestList2] = useState([]);
    const [pageCount1, setPageCount1] = useState(0);
    const [page1, setPage1] = useState(1);
    const [pageCount2, setPageCount2] = useState(0);
    const [page2, setPage2] = useState(1);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/my-test', {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            let allList = response.data.data;

            let testListTemp1 = allList?.filter(i => i?.is_evalute === 0);
            let testListTemp2 = allList?.filter(i => i?.is_evalute === 1);

            setTestList1(testListTemp1);
            setTestList2(testListTemp2);

            let pageCount1 = testListTemp1.length / perPage;
            if (pageCount1 > parseInt(pageCount1))
                pageCount1 = parseInt(pageCount1) + 1;

            setPageCount1(pageCount1);

            let pageCount2 = testListTemp2.length / perPage;
            if (pageCount2 > parseInt(pageCount2))
                pageCount2 = parseInt(pageCount2) + 1;

            setPageCount2(pageCount2);
        }).catch(error => {
            setTestList1([]);
            setTestList2([]);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token]);

    return (<React.Fragment>
        <Header2 />

        <div className={`${styles.Container}`}>
            <div className={`${styles.studentDashboard_Row}`}>
                <div className={`${styles.studentDashboard_BigDiv_Sec}`}>
                    <div className={`${styles.viewAll_Sec}`} style={{ margin: 0 }}>
                        <p className={`${styles.viewAll_LeftSideText}`} style={{ fontSize: '18px', fontWeight: 600 }}>My tests</p>
                    </div>

                    <div className={`${styles.title_Subjt_EnddateStatusAreaSec}`}>
                        <p className={`${styles.viewAll_LeftSideText}`} style={{ borderBottom: '1px solid #000000', fontSize: '16px' }}>Test List: Pending Evaluation</p>
                        <table>
                            <tr>
                                <th className={`${styles.th_HedingCustom}`}>Title</th>
                                <th className={`${styles.align_Center} ${styles.th_HedingCustom}`}>Subject</th>
                                <th className={`${styles.align_Center} ${styles.th_HedingCustom}`}>Quiz Date</th>
                                <th className={`${styles.align_Right} ${styles.th_HedingCustom}`}>Status</th>
                            </tr>
                            {testList1.map((item, index) => {
                                return (index >= ((page1 - 1) * perPage) && index < ((page1) * perPage)) ? (<tr key={index} className={`${styles.tr_NewTest}`} onClick={(e) => navigate('/student/quiz-result/' + item?.quiz_id)}>
                                    <td className={`${styles.title_Vertical}`}>{item.topic ? 'Chapter ' + item.topic?.topic_index + ': ' + item.topic?.title : 'All Chapter'} <p className={`${styles.smaLL_Ptag}`}>By Quizzer</p></td>
                                    <td className={`${styles.align_Center} ${styles.title_Vertical} ${styles.colr_Black}`}>{item.subject.title}</td>
                                    <td className={`${styles.align_Center} ${styles.endDate_Vertical}`}>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className={`${styles.align_Right} ${styles.title_Vertical} ${styles.colr_Green}`}>{item.total_marks}/{item.total_quiz_marks}</td>
                                </tr>) : null;
                            })}
                            {(pageCount1 > 0) && <Stack spacing={2} p>
                                <Pagination count={pageCount1} color="primary" page={page1} onChange={(e, v) => setPage1(v)} />
                            </Stack>}
                            {(testList1.length === 0) && <tr><td colspan={4}>
                                <div className={`${styles.noData_FoundSec}`}>
                                    <div className={`${styles.noData_Found_SecUnder}`}>
                                        <img src='/static/img/no-datafound.png' alt='' />
                                        <p>No tests are available now</p>
                                    </div>
                                </div></td>
                            </tr>}
                        </table>
                    </div>

                    <div className={`${styles.title_Subjt_EnddateStatusAreaSec}`}>
                        <p className={`${styles.viewAll_LeftSideText}`} style={{ borderBottom: '1px solid #000000', fontSize: '16px' }}>Test List: Evalated</p>
                        <table>
                            <tr>
                                <th className={`${styles.th_HedingCustom}`}>Title</th>
                                <th className={`${styles.align_Center} ${styles.th_HedingCustom}`}>Subject</th>
                                <th className={`${styles.align_Center} ${styles.th_HedingCustom}`}>Quiz Date</th>
                                <th className={`${styles.align_Right} ${styles.th_HedingCustom}`}>Status</th>
                            </tr>
                            {testList2.map((item, index) => {
                                return (index >= ((page2 - 1) * perPage) && index < ((page2) * perPage)) ? (<tr key={index} className={`${styles.tr_NewTest}`} onClick={(e) => navigate('/student/quiz-result/' + item?.quiz_id)}>
                                    <td className={`${styles.title_Vertical}`}>{item.topic ? 'Chapter ' + item.topic?.topic_index + ': ' + item.topic?.title : 'All Chapter'} <p className={`${styles.smaLL_Ptag}`}>By Quizzer</p></td>
                                    <td className={`${styles.align_Center} ${styles.title_Vertical} ${styles.colr_Black}`}>{item.subject.title}</td>
                                    <td className={`${styles.align_Center} ${styles.endDate_Vertical}`}>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className={`${styles.align_Right} ${styles.title_Vertical} ${styles.colr_Green}`}>{item.total_marks}/{item.total_quiz_marks}</td>
                                </tr>) : null;
                            })}
                            {(pageCount2 > 0) && <Stack spacing={2} p>
                                <Pagination count={pageCount2} color="primary" page={page2} onChange={(e, v) => setPage2(v)} />
                            </Stack>}
                            {(testList2.length === 0) && <tr><td colspan={4}>
                                <div className={`${styles.noData_FoundSec}`}>
                                    <div className={`${styles.noData_Found_SecUnder}`}>
                                        <img src='/static/img/no-datafound.png' alt='' />
                                        <p>No tests are available now</p>
                                    </div>
                                </div></td>
                            </tr>}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(MyTests);