import React, { useEffect, useState } from 'react';
import styles from './materials.module.css';
import { Link } from 'react-router-dom';
import Header1 from '../../components/header1';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';

const Materials = () => {
  const auth = authData();

  const [materialList, setMaterialList] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/material-list').then(response => {
      setMaterialList(response.data.data);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  const openFileUrl = (item) => {
    window.open(item?.file_url);
  }

  return (<React.Fragment>
    {auth ? <Header2 /> : <Header1 />}

    <div className={`${styles.Container}`}>
      <div className={`${styles.viewAll_Sec}`}>
        <p className={`${styles.viewAll_LeftSideText}`}>Study Material</p>
        <div className={`${styles.disflex}`}>
          <Link to={'/home'}><p className={`${styles.viewAll_RightSideText}`}>View all</p></Link>
          <Link to={'/home'}><i data-feather="chevron-right"></i></Link>
        </div>
      </div>
    </div>

    <div className={`${styles.Container}`}>
      <div className={`${styles.Row}`}>
        {materialList?.map(item => {
          return <div className={`${styles.SolutionsMainArae}`} key={item?._id}>
            <div className={`${styles.SolutionsCont}`} onClick={() => openFileUrl(item)}>
              <div>
                <p className={`${styles.SolutionsTextBig}`}>{item?.class?.title}</p>
                <p className={`${styles.SolutionsTextsmall}`}>{item?.title}</p>
              </div>
            </div>
          </div>
        })}
      </div>
    </div>

  </React.Fragment>);
}

export default Materials;