import React from 'react';
import styles from './header.module.css';
import { Link } from "react-router-dom";

function Header1() {
    return (<div className={`${styles.Header}`}>
        <div className={`${styles.Container}`}>
            <div className={`${styles.HeadRow}`}>
                <div className={`${styles.HeadLogo}`}></div>
                <div className={`${styles.Menu}`}>
                    <ul>
                        <li><Link to={'/home'}>Home</Link></li>
                        <li><Link to={'/student/explore-tests'}>Explore</Link></li>
                        <li><Link to={'/faq'}>FAQ</Link></li>
                        <li><Link to={'/contact-us'}>Contact Us</Link></li>
                    </ul>
                    <Link className={`${styles.HeadLoginBU}`} to={'/login'}>Join us</Link>
                </div>
            </div>
        </div>
    </div>);
}

export default Header1;