import React, { useEffect, useState } from 'react';
import styles from './laststep.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { useParams } from 'react-router-dom';
import { Menu, Link, Check } from 'react-feather';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import * as moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';

const LastStep = () => {
  const auth = authData();
  const { id } = useParams();
  const [testDet, setTestDet] = useState({});

  useEffect(() => {
    function getTest() {
      axios(process.env.REACT_APP_API_URL + 'mentor/test-details/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setTestDet(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getTest();
    }
  }, [auth?.token, id]);

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>

      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.Row}`}>
          <div className={`${styles.mainDIvBig_Sec}`}>
            <div className={`${styles.main_DivSec}`}>
              <div className={`${styles.mainDivSec_Smalldiv}`}>
                <div className={`${styles.textArea}`}>
                  <p className={`${styles.classHader}`}>{testDet?.class}</p>
                  <p className={`${styles.titleForHeding}`}>{testDet?.topic ? testDet?.topic : 'All Chapter'}</p>
                  <p className={`${styles.Subject}`}>{testDet?.subject}</p>
                  <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                </div>
                <div className={`${styles.buttomArea}`}>
                  {/*<button className={`${styles.share_Examlink}`}>Share Exam link</button>*/}
                  <CopyToClipboard text={'Test'}>
                    <button className={`${styles.copy_Examlink}`}><Link />Copy Exam link</button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className={`${styles.mainDivSec_Bigdiv}`}>
                <p className={`${styles.details_AreaHeding}`}>Details</p>
                <div className={`${styles.details_Area}`}>
                  <div className={`${styles.details_AreaClassSec}`}>
                    <p className={`${styles.ClassSecHeading}`}>Class : <span>{testDet?.class}</span></p>
                    <div className={`${styles.ClassSecGroupArea}`}>
                      <img src="/static/img/Vector.svg" className={`${styles.massgLogo}`} alt='' />
                      <div>
                        <p className={`${styles.GroupAreaSmallTitel}`}>No: of Questions</p>
                        <p className={`${styles.GroupAreaBigTitel}`}>{testDet?.total_question}</p>
                      </div>
                    </div>
                    <div className={`${styles.ClassSecGroupArea}`}>
                      <div className={`${styles.incosCK}`}><Check /></div>
                      <div>
                        <p className={`${styles.GroupAreaSmallTitel}`}>Total Marks</p>
                        <p className={`${styles.GroupAreaBigTitel}`}>{testDet?.total_marks}</p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.details_AreaClassSec}`}>
                    <p className={`${styles.ClassSecHeading}`}>Board : <span>{testDet?.board}</span></p>
                    <div className={`${styles.ClassSecGroupArea}`}>
                      <img src="/static/img/Vectortime.svg" className={`${styles.massgLogo}`} alt='' />
                      <div>
                        <p className={`${styles.GroupAreaSmallTitel}`}>Time allowed</p>
                        <p className={`${styles.GroupAreaBigTitel}`}>{(moment.duration(testDet?.time_limit, 'minutes').hours() > 0) && moment.duration(testDet?.time_limit, 'minutes').hours() + ' hr(s)'} {(moment.duration(testDet?.time_limit, 'minutes').minutes() > 0) && moment.duration(testDet?.time_limit, 'minutes').minutes() + ' min(s)'}</p>
                      </div>
                    </div>
                    <div className={`${styles.ClassSecGroupArea}`}>
                      <div className={`${styles.incosMS}`}><Menu /></div>
                      <div>
                        <p className={`${styles.GroupAreaSmallTitel}`}>Level</p>
                        <p className={`${styles.GroupAreaBigTitel}`}>{testDet?.level}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {(testDet?.start_date && testDet?.end_date) && <table>
                  <tr>
                    <th className={`${styles.moreDetailsText}`}>Quiz Opening Time</th>
                    <th className={`${styles.moreDetailsMonth}`}>{moment(testDet?.start_date).format('D MMMM YYYY')}</th>
                    <th className={`${styles.moreDetailsTime}`}>{moment(testDet?.start_date).format('hh:mm A')}</th>
                  </tr>
                  <tr>
                    <td className={`${styles.moreDetailsText}`}>Quiz Opening Time</td>
                    <td className={`${styles.moreDetailsMonth}`}>{moment(testDet?.end_date).format('D MMMM YYYY')}</td>
                    <td className={`${styles.moreDetailsTime}`}>{moment(testDet?.end_date).format('hh:mm A')}</td>
                  </tr>
                </table>}
              </div>
            </div>
          </div>
          <div className={`${styles.mainDIvSmall_Sec}`}>
            <div className={`${styles.total_QuestionsSec}`}>
              <ul className={`${styles.smallDIvSec2_CostUL}`}>
                <li>
                  <div className={`${styles.flex_li}`}>
                    <div className={`${styles.group_Icon}`}><Menu /></div>
                    <p className={`${styles.smallDIvSec2_CostTitle}`}>Hard Questions</p>
                  </div>
                  <p className={`${styles.smallDIvSec2_CostTitleNum}`}>{testDet?.total_hard_questions}</p>
                </li>
                <li>
                  <div className={`${styles.flex_li}`}>
                    <div className={`${styles.group_Icon} ${styles.background_Aqua}`}><Menu /></div>
                    <p className={`${styles.smallDIvSec2_CostTitle}`}>Medium Questions</p>
                  </div>
                  <p className={`${styles.smallDIvSec2_CostTitleNum}`}>{testDet?.total_medium_questions}</p>
                </li>
                <li>
                  <div className={`${styles.flex_li}`}>
                    <div className={`${styles.background_Green}`}><Menu /></div>
                    <p className={`${styles.smallDIvSec2_CostTitle}`}>Easy Questions</p>
                  </div>
                  <p className={`${styles.smallDIvSec2_CostTitleNum}`}>{testDet?.total_easy_questions}</p>
                </li>
                <div className={`${styles.boardSec}`}></div>
                <li>
                  <p className={`${styles.smallDIvSec2_CostTitle}`}>Total Questions</p>
                  <p className={`${styles.smallDIvSec2_CostTitleNum}`}>{testDet?.total_question}</p>
                </li>
              </ul>
            </div>
            {(testDet?.is_restrict) && <div className={`${styles.Login_DetailsSec}`}>
              <p className={`${styles.Login_DetailsHeading}`}>Login details</p>
              <div className={`${styles.accomplishment_FormField}`}>
                <label className={`${styles.accomplishment_Label}`}>Code</label>
                <label className={`${styles.number_TextTy}`} >{testDet?.quiz_code}</label>
              </div>
              <div className={`${styles.accomplishment_FormField}`}>
                <label className={`${styles.accomplishment_Label}`}>password</label>
                <label className={`${styles.number_TextTy}`} >{testDet?.quiz_password}</label>
              </div>
              <div className={`${styles.Button_CusRow}`}>
                <button className={`${styles.blueColer_BU}`}>Share Details</button>
                <button className={`${styles.aqueColer_BU}`}>Copy Details</button>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(LastStep);