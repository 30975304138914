import React, { Fragment, useEffect, useState } from 'react';
import styles from './testevalute2.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

const TestEvalute = () => {
  const navigate = useNavigate();
  const auth = authData();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);

  useEffect(() => {
    function getResults() {
      axios(process.env.REACT_APP_API_URL + 'mentor/evalute-details/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        let resD = response.data.data;
        if (resD.length > 0) {
          setData(resD[0]);
          setQuestionList(resD[0].questionlist);
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token && id) {
      getResults();
    }

  }, [id, auth?.token]);

  const openDetails = (qIndex) => {
    let questionListTemp = questionList.map((item, index) => {
      if (qIndex === index) {
        return { ...item, isOpen: !item.isOpen };
      }
      return { ...item, isOpen: false };
    });
    setQuestionList(questionListTemp);
  };

  const cngCheckbox = (e) => {
    if (e.target.checked) {
      if (!correctAnswers.includes(parseInt(e.target.value))) {
        setCorrectAnswers(prev => [...prev, parseInt(e.target.value)]);
      }
    } else {
      if (correctAnswers.includes(parseInt(e.target.value))) {
        setCorrectAnswers(prev => prev.filter(i => i !== parseInt(e.target.value)));
      }
    }
  }

  const evaluteTest = () => {
    let total_correct = 0;
    let total_easy_corrected = 0;
    let total_medium_corrected = 0;
    let total_hard_corrected = 0;
    let total_marks = 0;
    let total_evaluate = 0;

    let answers = data?.answers?.map((item, index) => {
      if (correctAnswers.includes(index)) {
        total_correct++;
        total_evaluate++;
        total_marks = total_marks + parseInt(questionList[index].marks);
        if (questionList[index]?.level === 'Hard') {
          total_hard_corrected++;
        }
        if (questionList[index]?.level === 'Medium') {
          total_medium_corrected++;
        }
        if (questionList[index]?.level === 'Easy') {
          total_easy_corrected++;
        }
        return { ...item, is_correct: true, is_evalute: 1, marks: questionList[index].marks };
      } else if (item) {
        if (item.is_evalute === 0)
          total_evaluate++;
        return { ...item, is_evalute: 1 };
      }
      return null;
    });

    let postData = { answers: answers, total_correct: data?.total_correct + total_correct, total_evaluate: data?.total_evaluate + total_evaluate, total_marks: data?.total_marks + total_marks, total_easy_corrected: data?.total_easy_corrected + total_easy_corrected, total_medium_corrected: data?.total_medium_corrected + total_medium_corrected, total_hard_corrected: data?.total_hard_corrected + total_hard_corrected, is_evalute: 1 };

    axios.post(process.env.REACT_APP_API_URL + 'mentor/evalute-test/' + id, postData, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      toast.success(response.data.message);
      setTimeout(() => {
        navigate('/mentor/tests');
      }, 2000);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>Test Evaluation</p>
            <p className={`${styles.my_DashboarSubHeading}`}>{data?.subject?.title}:: {data?.test?.title}</p>
            <p className={`${styles.my_DashboarSubHeading}`}>Student: {data?.student?.first_name} {data?.student?.last_name}</p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.MainSec_Area}`}>
          <div>
            <div className={`${styles.BigDiv_Sec_UnderBg}`}>
              <table className={`${styles.table}`}>
                <tr>
                  <th className={`${styles.th_Heading}`}>Questions</th>
                </tr>
                {questionList?.map((item, index) => {
                  return (<Fragment key={index}>
                    <tr onClick={(e) => openDetails(index)}>
                      <td>
                        <div className={`${styles.td_Group}`} style={{ position: 'relative' }}>
                          <img src="/static/img/massgicon.svg" alt="" style={{ height: '18px', marginTop: '4px' }} />
                          <p className={`${styles.blueTex}`} style={{ marginRight: '70px' }}><b>{item?.title}</b>:: {item?.description}</p>
                          <p className={`${styles.blueTex}`} style={{ position: 'absolute', right: '10px' }}><b>Marks:</b> {item?.marks}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={`${styles.halfBig_Div}`}>
                          <p className={`${styles.Big_DivHeading}`}><strong>Answer:</strong></p>
                          {(data?.questionlist[index]?.type === 'Single Select' || data?.questionlist[index]?.type === 'True/False') ? <TextField id="outlined-multiline-static" fullWidth multiline minRows={4} aria-readonly value={data?.questionlist[index]?.options[data?.answers[index]?.answer]?.title} /> : <TextField id="outlined-multiline-static" fullWidth multiline minRows={4} aria-readonly value={data?.answers[index]?.answer} />}

                          <FormControlLabel onChange={cngCheckbox} value={index} control={<Checkbox defaultChecked={data?.answers[index]?.is_correct} disabled={data?.answers[index]?.is_evalute === 1 || data?.questionlist[index]?.type !== 'Subjective'} />} label={'Correct'} key={index} />
                        </div>
                      </td>
                    </tr>
                  </Fragment>);
                })}
                {(questionList.length === 0) && <tr>
                  <div className={`${styles.noData_FoundSec}`}>
                    <div className={`${styles.noData_Found_SecUnder}`}>
                      <img src='/static/img/no-datafound.png' alt='' />
                      <p>No question are available now</p>
                    </div>
                  </div>
                </tr>}
              </table>
            </div>
          </div>
        </div>
        {data?.is_evalute === 0 && <div className={`${styles.ending_BUDiv}`}>
          <button type='button' className={`${styles.ending_BU}`} onClick={evaluteTest}>Save</button>
        </div>}
      </div>


    </div>
  </React.Fragment>)
}

export default withMentorAuth(TestEvalute);