import React from 'react';
import styles from './list.module.css';
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs, TextField, Button } from '@mui/material';
import DataTable from 'react-data-table-component';
import { X, ChevronDown } from 'react-feather';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import { TableLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import { toast } from 'react-smart-toaster';
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";

class UserList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [], filterText: '', modalShow: false, loading: false, contentLoading: true, currentItem: 0 };

    this.auth = authData();

    this.columns = [
      {
        name: 'Name',
        selector: row => row.first_name + ' ' + row.last_name,
        sortable: true,
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
      },
      {
        name: 'Status',
        cell: tableProps => ((tableProps.status === 1 || tableProps.status === "1") ? <Dropdown>
          <Dropdown.Toggle className={`${styles.ActiveBU}`} id={"dropdown-basic" + tableProps.id} size="sm">Active <ChevronDown /></Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={this.statusChange.bind(this, tableProps._id, 0)}>Archived</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> : <Dropdown>
          <Dropdown.Toggle className={`${styles.ArchiveBU}`} id={"dropdown-basic" + tableProps.id} size="sm">Archived <ChevronDown /></Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={this.statusChange.bind(this, tableProps._id, 1)}>Active</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>),
        sortable: false,
      }

    ];
  }

  async componentDidMount() {
    try {
      axios(process.env.REACT_APP_API_URL + 'users/mentor/list', {
        headers: { 'x-access-token': this.auth?.token }
      }).then(response => {

        let allData = response.data.data;

        this.setState({ ...this.state, allData: allData, data: allData, contentLoading: false });
      }).catch(error => {
        this.setState({ ...this.state, contentLoading: false });
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }
  onFilter(e) {
    this.setState({ ...this.state, filterText: e.target.value }, this.filterHandler);
  }

  clearFilter() {
    this.setState({ ...this.state, filterText: '' }, this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;
    if (this.state.filterText !== '') {
      let inputVal = this.state.filterText;
      filteredData = filteredData.filter((item) => {
        return item.first_name.toLowerCase().includes(inputVal) || item.last_name.toLowerCase().includes(inputVal) || item.email.toLowerCase().includes(inputVal) || item.phone.toLowerCase().includes(inputVal);
      });
    }
    this.setState({ ...this.state, data: filteredData });
  }

  statusChange(id, status) {
    this.setState({ ...this.state, loading: true });
    axios.put(process.env.REACT_APP_API_URL + 'users/' + id, { status: status }, {
      headers: { 'x-access-token': this.auth?.token }
    }).then(response => {
      toast.success(response.data.message);
      let allData = this.state.allData;
      let data = this.state.data;
      let allDataTemp = allData.map(i => {
        if (i._id === id)
          return { ...i, status: status }
        return i;
      });
      let dataTemp = data.map(i => {
        if (i._id === id)
          return { ...i, status: status }
        return i;
      });
      this.setState({ ...this.state, allData: allDataTemp, data: dataTemp, loading: false });
    }).catch(error => {
      this.setState({ ...this.state, loading: false });
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  render() {

    return (<>
      {this.state.loading && <Loader />}
      {this.state.contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
      {!this.state.contentLoading && <div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Student List</p>
          <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
            <Link color="inherit" to="/admin/dashboard">Dashboard</Link>
            <Link color="inherit" to={'/admin/student/list'}>Student</Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
          <div className='TableFilterHead'>
            <div className="DataTableSearch">
              <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={this.state.filterText} onChange={this.onFilter.bind(this)} />
              {this.state.filterText && <Button type="button" className="CloseBU" onClick={this.clearFilter.bind(this)}><X /></Button>}
            </div>
          </div>

          <div className='TableContent'>
            <DataTable className='DataTable'
              columns={this.columns}
              data={this.state.data}
              pagination
            />
          </div>
        </div>
      </div>}
    </>);
  }
}

export default withAdminAuth(UserList);
