import React, { useEffect, useState } from 'react';
import styles from './dashboard.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import * as moment from 'moment';
import { Button, TextField } from '@mui/material';
import { X } from 'react-feather';
import DataTable from 'react-data-table-component';

const Dashboard = () => {
  const auth = authData();
  const navigate = useNavigate();
  const [allTestList, setAllTestList] = useState([]);
  const [filteredTestList, setFilteredTestList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [upcomingList, setUpcomingList] = useState([]);

  useEffect(() => {
    function getTests() {
      axios(process.env.REACT_APP_API_URL + 'mentor/tests', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setAllTestList(response.data.data);
        setFilteredTestList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
    function getGroups() {
      axios(process.env.REACT_APP_API_URL + 'mentor/groups', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setGroupList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getTests();
      getGroups();
    }
  }, [auth?.token]);

  useEffect(() => {
    function getUpcomingTests() {
      axios(process.env.REACT_APP_API_URL + 'mentor/uplocoming-test', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setUpcomingList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token) {
      getUpcomingTests();
    }
  }, [auth?.token]);

  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Subject',
      cell: tableProps => (<div className={`${styles.title_VerticalFest_TD}`}>
        {tableProps?.subject?.title}
        {tableProps?.topic ? <p className={`${styles.smaLL_Ptag}`}>Chapter {tableProps?.topic?.topic_index}: {tableProps?.topic?.title}</p> : <p className={`${styles.smaLL_Ptag}`}>All Topics</p>}
      </div>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA?.subject?.title > rowB?.subject?.title) {
          return 1;
        }
        if (rowB?.subject?.title > rowA?.subject?.title) {
          return -1;
        }
        return 0;
      }
    },
    {
      name: 'Attemted',
      selector: row => row.total_attemted,
      sortable: true,
    },
    {
      name: 'End Date',
      selector: row => (row?.end_date) ? moment(row?.end_date).format('DD-MM-YYYY') : '-',
      sortable: true,
    },
    {
      name: 'Status',
      cell: tableProps => ((tableProps?.status === 1) ? <span className={`${styles.colr_Green}`}>Published</span> : <span className={`${styles.colr_Red}`}>Not Completed</span>),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA?.status > rowB?.status) {
          return 1;
        }
        if (rowB?.status > rowA?.status) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: '',
      cell: tableProps => (<div>
        {(tableProps?.added_by === auth?._id && tableProps?.status === 0) && <Link style={{ fontSize: '12px', fontWeight: 600, color: '#fff', padding: '8px', borderRadius: '5px', background: '#ff8414', marginRight: '3px' }} to={'/mentor/create-test/step-1/' + tableProps?._id}>Edit</Link>}
        {(tableProps?.added_by === auth?._id && tableProps?.not_evalute > 0) && <Link style={{ fontSize: '12px', fontWeight: 600, color: '#fff', padding: '8px', borderRadius: '5px', background: '#ff8414' }} to={'/mentor/test-evalute/' + tableProps?._id}>Evalute</Link>}
      </div>),
      sortable: false,
      right: true
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: '16px',
        color: '#4B4B4B',
        fontWeight: 600
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        color: '#191D63',
        fontWeight: 500
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFFFFF',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#E6ECF0',
        cursor: 'pointer'
      },
    },
  };

  const paginationComponentOptions = {
    noRowsPerPage: true
  };

  useEffect(() => {
    let filteredData = allTestList;

    if (filterText !== '') {
      filteredData = filteredData.filter((item) => {
        return item.title.toLowerCase().includes(filterText.toLowerCase()) || item.subject?.title.toLowerCase().includes(filterText.toLowerCase());
      });
    }
    setFilteredTestList(filteredData);
  }, [filterText, allTestList]);

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>Hi {auth?.first_name},</p>
            <p className={`${styles.my_DashboarSubHeading}`}>My Dashboard</p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.Row}`}>

          <div className={`${styles.BigDiv_Sec}`}>

            <div className={`${styles.text_ButtonGrop}`}>
              <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                <div className={`${styles.UnderSmLContGroup}`}>
                  <p className={`${styles.UnderSmLbigTitle}`}>My Tests</p>
                </div>
              </div>
              <div className={`${styles.UnderSmLContGroup2}`}>
                <Link to={'/mentor/create-test'} className={`${styles.My_TestsBU}`}><img src="/static/img/plesicons.svg" alt="" />Create New Test</Link>
              </div>
            </div>

            <div className={`${styles.table_Sec}`}>
              <div className='TableFilterHead'>
                <div className="DataTableSearch">
                  <TextField id="search" type="text" placeholder="Search by keyword" aria-label="Search Input" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                  {filterText && <Button type="button" className="CloseBU" onClick={(e) => setFilterText('')}><X /></Button>}
                </div>
              </div>
              <DataTable
                className={`${styles.table}`}
                columns={columns}
                data={filteredTestList}
                pagination
                customStyles={customStyles}
                paginationComponentOptions={paginationComponentOptions}
                onRowClicked={(e) => { ((e?.added_by === auth?._id) ? (e?.status === 1 && e?.not_evalute > 0) ? navigate('/mentor/test-evalute/' + e?._id) : navigate('/mentor/create-test/step-1/' + e?._id) : navigate('/mentor/view-test/' + e?._id)) }}
                highlightOnHover={true}
                noDataComponent={<div className={`${styles.noData_FoundSec}`}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' />
                    <p>No tests are available now</p>
                  </div>
                </div>}
              />
            </div>

            <div className={`${styles.text_ButtonGrop}`}>
              <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                <div className={`${styles.UnderSmLContGroup}`}>
                  <p className={`${styles.UnderSmLbigTitle}`}>My Groups</p>
                </div>
              </div>
              <div className={`${styles.UnderSmLContGroup2}`}>
                <Link to={'/mentor/create-group'} className={`${styles.My_TestsBU}`}><img src="/static/img/plesicons.svg" alt="" />Create New Group</Link>
              </div>
            </div>

            <div className={`${styles.Row}`}>
              {groupList.map(item => {
                return <Link to={'/mentor/tests/' + item?._id} className={`${styles.my_TestsDiv}`} key={item._id}>
                  <div>
                    <div className={`${styles.my_TestsUnder}`}>
                      <p className={`${styles.Subject}`}>{item?.classes}</p>
                      <p className={`${styles.my_TestsAlgebraTex} ${styles.my_TestsAlgebraTex2}`}>{item?.title}</p>
                      <p className={`${styles.my_TestsAlgebraTex}`}>{item?.description}</p>
                    </div>
                  </div>
                </Link>
              })}

              {(groupList.length === 0) && <div style={{ width: '100%', margin: '14px 8px 0' }}>
                <div className={`${styles.noData_FoundSec}`} style={{ borderRadius: '8px' }}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' />
                    <p>No groups are available now</p>
                  </div>
                </div>
              </div>}

            </div>
          </div>

          <div className={`${styles.SmallDiv_Sec}`}>
            <div className={`${styles.smallDIvSec}`}>
              {/*<p className={`${styles.smallDIvSecTitel}`}>Latest News</p>
              <div className={`${styles.Latest_NewskHalfGroup}`}>
                <div className={`${styles.imgDiv} ${styles.pink_Colr}`}>
                  <img src="/static/img/timericon.svg" alt="" />
                </div>
                <div>
                  <p className={`${styles.LeagueRankHalfGroupNubText}`}>Physic quiz in 2 hrs 45 mins</p>
                  <p className={`${styles.LeagueRankHalfGroupNamText}`}>10:00 to 11:00</p>
                </div>
              </div>*/}
              <p className={`${styles.smallDIvSecTitel}`}>Upcoming Tests</p>
              {upcomingList?.map(item => {
                return <div className={`${styles.smallDIvSecGroup}`} key={item._id}>
                  <div>
                    <p className={`${styles.smallDIvSecGroupBigText}`}>{item?.subject?.title} :: {item?.title}</p>
                    <p className={`${styles.smallDIvSecGroupSmallText}`}> {item?.topic ? 'Chapter ' + item?.topic?.topic_index + ': ' + item?.topic?.title : 'All Topics'}</p>
                  </div>
                  <div>
                    <p className={`${styles.smallDIvSecGroupBigText}`}>Start On</p>
                    <p className={`${styles.smallDIvSecGroupSmallText}`}>{moment(item?.start_date).format('DD/MM/YYYY LT')}</p>
                  </div>
                </div>
              })}
              {upcomingList?.length === 0 && <div style={{ width: '100%', margin: '14px 8px 0' }}>
                <div className={`${styles.noData_FoundSec}`} style={{ borderRadius: '8px', height: '200px' }}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' style={{ width: '80px' }} />
                    <p style={{ fontSize: '20px' }}>No upcoming tests are available now</p>
                  </div>
                </div>
              </div>}
            </div>
            {/*<div className={`${styles.smallDIvSec2}`}>
              <p className={`${styles.smallDIvSecTitel}`}>Notifications</p>
              <div className={`${styles.Latest_NewskHalfGroup}`}>
                <div className={`${styles.imgDiv} ${styles.pink_Colr}`}>
                  <img src="/static/img/timericon.svg" alt="" />
                </div>
                <div>
                  <p className={`${styles.LeagueRankHalfGroupNubText}`}>Physic quiz in 2 hrs 45 mins</p>
                  <p className={`${styles.LeagueRankHalfGroupNamText}`}>10:00 to 11:00</p>
                </div>
              </div>
              <p className={`${styles.smallDIvSecTitel}`}>Upcoming Tests</p>
              <div className={`${styles.smallDIvSecGroup}`} >
                <div>
                  <p className={`${styles.smallDIvSecGroupBigText}`}>Maths quiz</p>
                  <p className={`${styles.smallDIvSecGroupSmallText}`}>Chapter 4: Force part 3</p>
                </div>
                <div>
                  <p className={`${styles.smallDIvSecGroupBigText}`}>Deadline</p>
                  <p className={`${styles.smallDIvSecGroupSmallText}`}>Today , 9 pm</p>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default withMentorAuth(Dashboard);