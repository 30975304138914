import React, { useEffect, useState } from 'react';
import styles from './myvault.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { Link } from 'react-router-dom';

const MyVault = () => {
    const auth = authData();
    const [data, setData] = useState([]);

    useEffect(() => {
        function getVaults() {
            axios(process.env.REACT_APP_API_URL + 'student/vault', {
                headers: { 'x-access-token': auth?.token }
            }).then(response => {
                let resData = response.data.data;
                let tmpArr = [];
                let tmpArr2 = [];
                for (let n in resData) {
                    if (typeof (tmpArr[resData[n].class]) === 'undefined') {
                        tmpArr[resData[n].class] = [];
                    }
                    tmpArr[resData[n].class].push(resData[n]);
                }
                if (tmpArr.length > 0) {
                    for (let n in tmpArr) {
                        tmpArr2.push({ 'class': n, childrens: tmpArr[n] });
                    }
                }
                setData(tmpArr2);
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Error occured. Please try again");
                }
            });
        }

        if (auth?.token) {
            getVaults();
        }
    }, [auth?.token]);

    const handleClick = (qIndex) => {
        let dataTemp = data.map((item, index) => {
            if (qIndex === index) {
                return { ...item, isOpen: !item.isOpen };
            }
            return { ...item, isOpen: false };
        });
        setData(dataTemp);
    };

    return (<React.Fragment>
        <div className={`${styles.background_Color}`}>
            <Header2 />

            <div className={`${styles.Container}`}>
                <div className={`${styles.MainDivArea}`}>
                    <div className={`${styles.My_VaultSec}`}>
                        <p>{auth?.first_name}'s Vault</p>
                    </div>
                    <div className={`${styles.My_testsSec}`}>
                        <div className={`${styles.My_testsGroup}`}>
                            <p className={`${styles.My_testsBigTitel}`}>My Vault</p>
                        </div>
                    </div>
                    <div className={`${styles.tableSec}`}>
                        <table className={`${styles.tabelCls}`}>
                            {data.map((item, index) => {
                                return <><tr key={index} style={{ cursor: "pointer" }} onClick={() => handleClick(index)}>
                                    <td>
                                        <div className={`${styles.classAroDiv}`}>
                                            {(item?.isOpen) ? <img src="/static/img/downaroicon.svg" alt="" /> : <img src="/static/img/rightaroicon.svg" alt="" />}
                                            <p className={`${styles.classAroDivText}`}>Class {item.class}</p>
                                        </div>
                                    </td>
                                </tr>
                                    {(item?.isOpen) ? item.childrens.map((item2, index2) => {
                                        return <tr className={`${styles.relative_Position}`} key={index + index2}>
                                            <td className={(index2 % 3 === 0) ? ((index2 % 3 === 1) ? `${styles.td_TitelColr_pink}` : `${styles.td_TitelColr_yellow}`) : `${styles.td_TitelColr_green}`}>{item2?.accomplishment}</td>
                                            <td className={`${styles.td_Titel} ${styles.align_Center}`}>{item2?.competition}</td>
                                            <td className={`${styles.td_Titel} ${styles.align_Center}`}>{item2?.class}</td>
                                            <td className={`${styles.td_Titel} ${styles.align_Center}`}>{item2?.date}</td>
                                            <td className={`${styles.td_Titel} ${styles.align_Center}`}><a href={item2?.image_url} target='_blank' rel="noreferrer"><img src="/static/img/imgicon.svg" alt="" /></a></td>
                                        </tr>;
                                    }) : null}
                                </>
                            })}
                            {(data?.length === 0) && <tr>
                                <td colSpan={5}>
                                    <div className={`${styles.noData_FoundSec}`}>
                                        <div className={`${styles.noData_Found_SecUnder}`}>
                                            <img src='/static/img/no-datafound.png' alt='' />
                                            <p>No vaults are available now</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>}
                        </table>
                        <Link className={`${styles.tableBU}`} to={'/student/add-vault'}>Add new Accomplishment</Link>
                    </div>
                </div>
            </div>

        </div>
    </React.Fragment>);
}

export default withStudentAuth(MyVault);