import React from 'react';
import styles from './dashboard.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import withStudentAuth from '../../components/withStudentAuth';
import ClassArea from '../../components/classArea';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-smart-toaster';
import * as moment from 'moment';
import { ChevronRight } from 'react-feather';
import { Stack, Pagination } from '@mui/material';

const Dashboard = () => {
    const auth = authData();
    const navigate = useNavigate();
    const perPage = 8;
    const [testList, setTestList] = useState([]);
    const [upcomingList, setUpcomingList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/my-test', {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setTestList(response.data.data);

            let pageCount = response.data.data.length / perPage;
            if (pageCount > parseInt(pageCount))
                pageCount = parseInt(pageCount) + 1;

            setPageCount(pageCount);
        }).catch(error => {
            setTestList([]);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token]);

    useEffect(() => {
        function getUpcomingTests() {
            axios(process.env.REACT_APP_API_URL + 'mentor/uplocoming-test', {
                headers: { 'x-access-token': auth?.token }
            }).then(response => {
                setUpcomingList(response.data.data);
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Error occured. Please try again");
                }
            });
        }
        if (auth?.token) {
            getUpcomingTests();
        }
    }, [auth?.token]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'public/group-list').then(response => {
            setGroupList(response.data.data.splice(0, 4));
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, []);

    return (<React.Fragment>
        <Header2 />

        <div className={`${styles.Container}`}>
            <div className={`${styles.Your_ScoresMainArea}`}>
                <div className={`${styles.Your_ScoresNameSec}`}>
                    <div className={`${styles.Your_ScoresNameSecHalf}`}>
                        <p className={`${styles.NameSecHalfNam}`}>Hi {auth?.first_name},</p>
                        <p className={`${styles.NameSecHalfNamSub}`}>Hope you had a great day at school</p>
                    </div>
                </div>
                <img style={{ width: '259px', height: '228px' }} src="/static/img/Frame1.svg" alt="" />
                <div className={`${styles.boderArea}`}></div>
                <div className={`${styles.your_LeagueRank}`}>
                    <div className={`${styles.your_LeagueRankHalf}`}>
                        <p className={`${styles.your_LeagueRankHalfHeding}`}>Your league rank</p>
                        <div className={`${styles.your_LeagueRankHalfGroup}`}>
                            <img src="/static/img/user-logo.svg" alt="" />
                            <div>
                                {/*<p className={`${styles.your_LeagueRankHalfGroupNubText}`}>#27</p>*/}
                                <p className={`${styles.your_LeagueRankHalfGroupNamText}`}>{auth?.first_name} {auth?.last_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.Your_ScoresHalf}`} style={{ minHeight: '50px' }}>
                        {/*<p className={`${styles.Your_ScoresHalfTitel}`}>Your Scores</p>
                        <div className={`${styles.Your_ScoresFaild}`}>
                            <p className={`${styles.Your_ScoresFaildText}`}>Gold stars</p>
                            <div className={`${styles.num_sterGrop}`}>
                                <p className={`${styles.Your_ScoresFaildNum}`}>355</p>
                                <img style={{ width: '17.114px', height: '16.356px' }} src="/static/img/star-logo.svg" alt="" />
                            </div>
                        </div>
                        <div className={`${styles.Your_ScoresFaild}`}>
                            <p className={`${styles.Your_ScoresFaildText} ${styles.Green__StarColr}`}>Green stars</p>
                            <div className={`${styles.num_sterGrop}`}>
                                <p className={`${styles.Your_ScoresFaildNum} ${styles.Green__StarColr}`}>355</p>
                                <img style={{ width: '17.114px', height: '16.356px' }} src="/static/img/greenColer.svg" alt="" />
                            </div>
                        </div>*/}
                    </div>
                    <div className={`${styles.my_DashboardSec}`}>
                        <Link to={'/student/my-reports'} className={`${styles.my_DashboardSecTxt}`}>My Dashboard <ChevronRight /></Link>
                    </div>
                </div>
            </div>
        </div>

        <div className={`${styles.Container}`}>
            <div className={`${styles.studentDashboard_Row}`}>
                <div className={`${styles.studentDashboard_BigDiv_Sec}`}>

                    <div className={`${styles.viewAll_Sec}`}>
                        <p className={`${styles.viewAll_LeftSideText}`}>My tests</p>
                        <div className={`${styles.disflex}`}>
                            <Link to={'/dashboard'}><p className={`${styles.viewAll_RightSideText}`}>View all</p></Link>
                            <Link to={'/dashboard'}><i data-feather="chevron-right"></i></Link>
                        </div>
                    </div>

                    <div className={`${styles.title_Subjt_EnddateStatusAreaSec}`}>
                        <table>
                            <tr>
                                <th className={`${styles.th_HedingCustom}`}>Title</th>
                                <th className={`${styles.align_Center} ${styles.th_HedingCustom}`}>Subject</th>
                                <th className={`${styles.align_Center} ${styles.th_HedingCustom}`}>Quiz Date</th>
                                <th className={`${styles.align_Right} ${styles.th_HedingCustom}`}>Status</th>
                            </tr>
                            {testList.map((item, index) => {
                                return (index >= ((page - 1) * perPage) && index < ((page) * perPage)) ? (<tr key={index} className={`${styles.tr_NewTest}`} onClick={(e) => navigate('/student/quiz-result/' + item?.quiz_id)}>
                                    <td className={`${styles.title_Vertical}`}>{item.topic ? 'Chapter ' + item.topic?.topic_index + ': ' + item.topic?.title : 'All Chapter'} <p className={`${styles.smaLL_Ptag}`}>By Quizzer</p></td>
                                    <td className={`${styles.align_Center} ${styles.title_Vertical} ${styles.colr_Black}`}>{item.subject.title}</td>
                                    <td className={`${styles.align_Center} ${styles.endDate_Vertical}`}>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className={`${styles.align_Right} ${styles.title_Vertical} ${styles.colr_Green}`}>{item.total_marks}/{item.total_quiz_marks}</td>
                                </tr>) : null;
                            })}
                            {(pageCount > 0) && <Stack spacing={2} p>
                                <Pagination count={pageCount} color="primary" page={page} onChange={(e, v) => setPage(v)} />
                            </Stack>}
                            {(testList.length === 0) && <tr><td colspan={4}>
                                <div className={`${styles.noData_FoundSec}`}>
                                    <div className={`${styles.noData_Found_SecUnder}`}>
                                        <img src='/static/img/no-datafound.png' alt='' />
                                        <p>No tests are available now</p>
                                    </div>
                                </div></td>
                            </tr>}
                        </table>
                    </div>

                    <div className={`${styles.viewAll_Sec}`}>
                        <p className={`${styles.viewAll_LeftSideText}`}>My Groups</p>
                        <div className={`${styles.disflex}`}>
                            <Link to={'/dashboard'}><p className={`${styles.viewAll_RightSideText}`}>View all</p></Link>
                            <Link to={'/dashboard'}><i data-feather="chevron-right"></i></Link>
                        </div>
                    </div>

                    <div className={`${styles.Row}`}>
                        <div className={`${styles.class_SubjectMainArea}`}>
                            <div className={`${styles.class_SubjectCont}`}>
                                <div className={`${styles.class_SubjectDiv}`}>
                                    <div className={`${styles.image_TextSec}`}>
                                        <img src="/static/img/Rectangle 20jpg.jpg" alt='' className={`${styles.SubjectContImg}`} />
                                        <div className={`${styles.TextGroup}`}>
                                            <p className={`${styles.titleForHeding}`}>Algebra</p>
                                            <p className={`${styles.Subject}`}>Maths</p>
                                            <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={`${styles.ClassTex}`}>Class 9</p>
                                        <p className={`${styles.Section}`}>CBSC</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.class_SubjectMainArea}`}>
                            <div className={`${styles.class_SubjectCont}`}>
                                <div className={`${styles.class_SubjectDiv}`}>
                                    <div className={`${styles.image_TextSec}`}>
                                        <img src="/static/img/Rectangle 20jpg.jpg" alt='' className={`${styles.SubjectContImg}`} />
                                        <div className={`${styles.TextGroup}`}>
                                            <p className={`${styles.titleForHeding}`}>Algebra</p>
                                            <p className={`${styles.Subject}`}>Maths</p>
                                            <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={`${styles.ClassTex}`}>Class 9</p>
                                        <p className={`${styles.Section}`}>CBSC</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.class_SubjectMainArea}`}>
                            <div className={`${styles.class_SubjectCont}`}>
                                <div className={`${styles.class_SubjectDiv}`}>
                                    <div className={`${styles.image_TextSec}`}>
                                        <img src="/static/img/Rectangle 20jpg.jpg" alt='' className={`${styles.SubjectContImg}`} />
                                        <div className={`${styles.TextGroup}`}>
                                            <p className={`${styles.titleForHeding}`}>Algebra</p>
                                            <p className={`${styles.Subject}`}>Maths</p>
                                            <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={`${styles.ClassTex}`}>Class 9</p>
                                        <p className={`${styles.Section}`}>CBSC</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.studentDashboard_SmallDiv_Sec}`}>
                    <div className={`${styles.studentDashboard_SmallDiv_Area}`}>
                        <p className={`${styles.smallDiv_secTitel}`}>Upcoming Tests</p>
                        {upcomingList?.map(item => {
                            return <div className={`${styles.Upcoming_TestsHalfGroup}`} key={item._id}>
                                <div>
                                    <p className={`${styles.LeagueRankHalfGroupNumText}`}>Maths quiz</p>
                                    <p className={`${styles.LeagueRankHalfGroupNamText}`}>Chapter 4: Force part 3</p>
                                </div>
                                <div>
                                    <p className={`${styles.LeagueRankHalfGroupNumText}`}>Deadline</p>
                                    <p className={`${styles.LeagueRankHalfGroupNamText}`}>Today , 9 pm</p>
                                </div>
                            </div>
                        })}
                        {upcomingList?.length === 0 && <div className={`${styles.noData_FoundSec}`} style={{ height: '200px' }}>
                            <div className={`${styles.noData_Found_SecUnder}`}>
                                <img src='/static/img/no-datafound.png' alt='' style={{ width: '80px' }} />
                                <p style={{ fontSize: '20px' }}>No tests are available now</p>
                            </div>
                        </div>}
                    </div>
                </div>

            </div>

        </div>

        <ClassArea />

        <div className={`${styles.Container}`}>
            <div className={`${styles.viewAll_Sec}`}>
                <p className={`${styles.viewAll_LeftSideText}`}>Start learning</p>
                <div className={`${styles.disflex}`}>
                    <Link to={'/dashboard'}><p className={`${styles.viewAll_RightSideText}`}>View all</p></Link>
                    <Link to={'/dashboard'}><i data-feather="chevron-right"></i></Link>
                </div>
            </div>
        </div>

        <div className={`${styles.Container}`}>
            <div className={`${styles.Row}`}>
                <div className={`${styles.Explore_EndingHalf}`}>
                    <div className={`${styles.Explore_EndingCusRow}`}>
                        {groupList?.map((item, index) => {
                            let className = `${styles.Explore_EndingMainCont}`;
                            if (index === 1)
                                className = `${styles.Explore_EndingMainCont} ${styles.yelloColr__Pro}`;
                            if (index === 2)
                                className = `${styles.Explore_EndingMainCont} ${styles.purpleColr__Pro}`;
                            if (index === 3)
                                className = `${styles.Explore_EndingMainCont} ${styles.greenColr__Pro}`;
                            return <div className={`${styles.Explore_EndingFeild}`}>
                                <div className={className}>
                                    <p className={`${styles.classText}`}>{item?.classes}</p>
                                    <p className={`${styles.subjectText}`}>{item?.title}</p>
                                    <p className={`${styles.subjectMiniText}`}>{item?.description}</p>
                                    <Link to={"/student/group-test-list/" + item._id} className={`${styles.Explore_EndingButton}`}>Explore</Link>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className={`${styles.Explore_EndingHalf}`}>
                    <div className={`${styles.Explore_EndingHalfMainCont}`}>
                        <p className={`${styles.Explore_EndingHalfMainContText}`}>Explore Groups where you<br />can find your dream class</p>
                        <img src="/static/img/Frame1.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(Dashboard);