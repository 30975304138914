import React, { useEffect } from 'react';
import styles from './createanswers.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, Radio } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import Loader from '../../../admin/components/Loader';

const CreateAnswer = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { tId, qId } = useParams();
  const [err, setErr] = useState({ chk: false });
  const [preview, setPreview] = useState({ index_0: null, index_1: null, index_2: null, index_3: null });
  const [uploadFiles, setUploadFiles] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [noOfOptions, setNoOfOptions] = useState(4);
  const [questionType, setQuestionType] = useState('Single Select');
  const { handleSubmit, register, formState: { errors }, control, setValue } = useForm({
    defaultValues: { options: [{ title: '', is_correct: false }, { title: '', is_correct: false }] }
  });

  const { fields, replace, append } = useFieldArray({
    control,
    name: "options"
  });

  useEffect(() => {
    function getQuestionDetails() {
      axios(process.env.REACT_APP_API_URL + 'mentor/question/' + qId, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setQuestionType(response.data.data.type);
        setNoOfOptions(4);
        if (response.data.data.options.length) {
          replace(response.data.data.options);

          let uploadFilesTemp = ['', '', '', ''];
          let previewTemp = { index_0: null, index_1: null, index_2: null, index_3: null };
          response.data.data.options.forEach((i, index) => {
            if (index === 0) {
              previewTemp = { ...previewTemp, index_0: i.image_url };
            }
            if (index === 1) {
              previewTemp = { ...previewTemp, index_1: i.image_url };
            }
            if (index === 2) {
              previewTemp = { ...previewTemp, index_2: i.image_url };
            }
            if (index === 3) {
              previewTemp = { ...previewTemp, index_3: i.image_url };
            }

            uploadFilesTemp[index] = i.image;
          });

          setPreview(previewTemp);
          setUploadFiles(uploadFilesTemp);
        } else {
          if (response.data.data.type === 'True/False') {
            setNoOfOptions(2);
            replace([{ title: 'True', is_correct: false }, { title: 'False', is_correct: false }]);
          }
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
    if (qId) {
      getQuestionDetails();
    }
  }, [qId, auth?.token, replace]);


  const onSubmit = (data) => {
    setErr({ chk: false });
    let errorObj = { chk: true };

    let isError = true;
    data.options.map((i) => {
      if (i.is_correct) {
        isError = false;
        errorObj = { ...errorObj, chk: false };
      }
      return false;
    });

    if (isError) {
      setErr(errorObj);
      return false;
    }

    let options = data.options.map((i, index) => {
      delete i.image_url;
      return { ...i, image: uploadFiles[index] };
    });

    axios.put(process.env.REACT_APP_API_URL + 'mentor/update-question/' + qId, { options: options, remark: data.remark }, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/create-test/step-3/' + tId);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });

  }

  const cngChk = (chk, index) => {
    fields.map((i, ind) => {
      if (chk && index !== ind) {
        setValue(`options.${ind}.is_correct`, false);
      } else {
        setValue(`options.${ind}.is_correct`, true);
      }
      return false;
    })
  }

  const cngChk2 = (chk, index) => {
    fields.map((i, ind) => {
      if (index === ind) {
        setValue(`options.${ind}.is_correct`, chk);
      }
      return false;
    })
  }

  const handleFileChange = (e, index) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(null)
      return;
    }

    let uploadFilesTemp = uploadFiles;
    let previewTemp = preview;

    setLoading(true);

    const postData = new FormData();
    postData.append('file', e.target.files[0]);

    axios.post(process.env.REACT_APP_API_URL + 'public/upload-file', postData, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      setLoading(false);

      if (index === 0) {
        previewTemp = { ...previewTemp, index_0: response.data.data.url };
      }
      if (index === 1) {
        previewTemp = { ...previewTemp, index_1: response.data.data.url };
      }
      if (index === 2) {
        previewTemp = { ...previewTemp, index_2: response.data.data.url };
      }
      if (index === 3) {
        previewTemp = { ...previewTemp, index_3: response.data.data.url };
      }

      uploadFilesTemp[index] = response.data.data.name;

      setPreview(previewTemp);
      setUploadFiles(uploadFilesTemp);

    }).catch(error => {
      setLoading(false);
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  return (<React.Fragment>

    <div className={`${styles.background_Color}`}>
      {loading && <Loader />}
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.MainDiv_Area}`}>
          <p className={`${styles.general_TextAroSecTitel}`}>General / Question / Options</p>
          <div className={`${styles.Tex_InputChackGrop}`}>
            <div className={`${styles.general_TextAroSec}`}>
              <img src="/static/img/rightsidearoicon.svg" alt="" />
              <p className={`${styles.general_TextAroSecTitel1}`}>Options</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>

            {fields.map((item, index) => {
              return (<div key={item.id} className={`${styles.option_InputGroup}`}>
                <div className={`${styles.chack_Box_SmallDiv}`}>
                  <p>Option {index + 1}</p>
                </div>
                <div className={`${styles.input_Button_BigDiv}`}>
                  <input type="text" placeholder="Option" className={`${styles.accomplishment_inputTy}`} {...register(`options.${index}.title`, { required: 'This field is required' })} readOnly={(questionType === 'True/False')} />
                  {(errors && errors.options && errors.options[index] && errors.options[index].title) && <p className={`${styles.login_ErrorM}`}>{errors.options[index].title?.message}</p>}

                  {(questionType === 'Single Select' || questionType === 'Muliple Select') && <><input style={{ 'display': 'none' }} id={"file" + index} type="file" name="file" accept="image/*" onChange={(e) => handleFileChange(e, index)} />
                    <label htmlFor={"file" + index} className={`${styles.chackBoxButton}`}>Add Attachment</label>
                    {(preview && preview['index_' + index]) && <img src={preview['index_' + index]} style={{ 'maxWidth': '300px', 'maxHeight': '150px' }} alt='' />}</>}
                </div>
                <div className={`${styles.chack_Box_SmallDiv}`}>
                  <div className={`${styles.checkbox_Flex}`}>
                    {(questionType === 'Muliple Select') ? <Controller label="Correct" control={control} {...register(`options.${index}.is_correct`)} render={({ field }) => (
                      <FormControlLabel className={`${styles.chackBox_labelTex}`} {...field} onChange={(e) => { field.onChange(e); cngChk2(e.target.checked, index); }} control={<Checkbox checked={field.value} />} label="Correct" />
                    )} /> : <Controller label="Correct" control={control} {...register(`options.${index}.is_correct`)} render={({ field }) => (
                      <FormControlLabel className={`${styles.chackBox_labelTex}`} {...field} onChange={(e) => { field.onChange(e); cngChk(e.target.checked, index); }} control={<Radio checked={field.value} />} label="Correct" />
                    )} />}

                  </div>
                </div>
              </div>);
            })}

            {err?.chk && <p className={`${styles.login_ErrorMB}`}>Please select correct answer</p>}

            {(fields.length < noOfOptions) && <button type='button' className={`${styles.ending_SaveBU1}`} onClick={(e) => append({ title: '', is_correct: false })}>Add Option</button>}

            <div className={`${styles.Button_WhiteColerDiv}`}>
              <div className={`${styles.general_TextAroSec}`}>
                <p className={`${styles.general_TextAroSecTitel2}`}>Remark</p>
              </div>
              <textarea placeholder="Remark" className={`${styles.accomplishment_inputTyWhiteColer}`} {...register(`remark`, { required: 'This field is required' })}></textarea>
              {(errors && errors.remark) && <p className={`${styles.login_ErrorM}`}>{errors.remark?.message}</p>}
            </div>

            <div className={`${styles.ending_SaveSec}`}>
              <button className={`${styles.ending_SaveBU}`}>Save Question</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(CreateAnswer);