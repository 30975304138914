import React, { useEffect, useState } from 'react';
import styles from './explore-test.module.css';
import { Link, useNavigate } from 'react-router-dom';
import withStudentAuth from '../../components/withStudentAuth';
import Header2 from '../../components/header2';
import Select from "react-select";
import { toast } from 'react-smart-toaster';
import axios from 'axios';
import { authData } from '../../components/getAuth';

let colorClasses1 = [
  styles.SubjectContColor1,
  styles.SubjectContColor2,
  styles.SubjectContColor3,
  styles.SubjectContColor4
];

let colorClasses2 = [
  styles.the_Circle1,
  styles.the_Circle2,
  styles.the_Circle3,
  styles.the_Circle4
];

const ExploreTest = () => {
  const auth = authData();
  const navigate = useNavigate();

  const [boardDDList, setBoardDDList] = useState([]);
  const [defaultBoard, setDefaultBoard] = useState(null);
  const [classDDList, setClassDDList] = useState([]);
  const [defaultClass, setDefaultClass] = useState(null);
  const [subjectAllDDList, setSubjectAllDDList] = useState([]);
  const [subjectDDList, setSubjectDDList] = useState([]);
  const [defaultSubject, setDefaultSubject] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    const testFilterData = localStorage.getItem("testFilterData");
    if (testFilterData) {
      let testFilterDataTemp = JSON.parse(testFilterData);
      if (testFilterDataTemp?.subject_id) {
        delete testFilterDataTemp?.subject_id;
      }
      if (testFilterDataTemp?.topic_id) {
        delete testFilterDataTemp?.topic_id;
      }
      getSubjectList(testFilterDataTemp);
      setSearchParams(testFilterDataTemp);
    } else {
      let testFilterDataArr = {};
      if (auth?.student_info?.board_id) {
        testFilterDataArr = { ...testFilterDataArr, board_id: auth?.student_info?.board_id };
      }
      if (auth?.student_info?.class_id) {
        testFilterDataArr = { ...testFilterDataArr, class_id: auth?.student_info?.class_id };
      }
      getSubjectList(testFilterDataArr);
      localStorage.setItem("testFilterData", JSON.stringify(testFilterDataArr));
      setSearchParams(testFilterDataArr);
    }
  }, [auth?.student_info?.board_id, auth?.student_info?.class_id]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/boards').then(response => {
      let boardListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setBoardDDList(boardListTemp);
      const found = boardListTemp.find(i => i.value === searchParams?.board_id);
      setDefaultBoard(found);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, [searchParams]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setClassDDList(classListTemp);
      const found = classListTemp.find(i => i.value === searchParams?.class_id);
      setDefaultClass(found);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, [searchParams]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data;
      setSubjectAllDDList(subjectListTemp);
      const found = subjectListTemp.map(i => { return { value: i._id, label: i.title }; }).find(i => i.value === searchParams?.board_id);
      setDefaultSubject(found);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, [searchParams]);

  useEffect(() => {
    if (searchParams?.board_id && searchParams?.class_id) {
      let subjectListTemp = subjectAllDDList.filter(i => (i.boards.indexOf(searchParams?.board_id) > -1 && i.classes.indexOf(searchParams?.class_id) > -1)).map(i => { return { value: i._id, label: i.title }; });
      setSubjectDDList(subjectListTemp);
    }
  }, [searchParams?.board_id, searchParams?.class_id, subjectAllDDList]);

  const goToList = (topicId, subjectId) => {
    const testFilterData = localStorage.getItem("testFilterData");
    if (testFilterData) {
      let testFilterDataArr = JSON.parse(testFilterData);
      testFilterDataArr = { ...testFilterDataArr, subject_id: subjectId, topic_id: topicId };
      localStorage.setItem("testFilterData", JSON.stringify(testFilterDataArr));
    }
    navigate('/student/test-list');
  }

  const cngBoard = (e) => {
    let testFilterDataArr = searchParams;
    testFilterDataArr = { ...testFilterDataArr, board_id: e.value };
    getSubjectList(testFilterDataArr);
    localStorage.setItem("testFilterData", JSON.stringify(testFilterDataArr));
    setSearchParams(testFilterDataArr);
  }

  const cngClass = (e) => {
    let testFilterDataArr = searchParams;
    testFilterDataArr = { ...testFilterDataArr, class_id: e.value };
    getSubjectList(testFilterDataArr);
    localStorage.setItem("testFilterData", JSON.stringify(testFilterDataArr));
    setSearchParams(testFilterDataArr);
  }

  const cngSubject = (e) => {
    let testFilterDataArr = searchParams;
    testFilterDataArr = { ...testFilterDataArr, subject_id: e.value };
    getSubjectList(testFilterDataArr);
    localStorage.setItem("testFilterData", JSON.stringify(testFilterDataArr));
    setSearchParams(testFilterDataArr);
  }

  const getSubjectList = (params) => {
    axios.get(process.env.REACT_APP_API_URL + 'public/subject-list', {
      params,
      paramsSerializer: function paramsSerializer(params) {
        return Object.entries(Object.assign({}, params)).map(([key, value]) => `${key}=${value}`).join('&');
      }
    }).then(response => {
      setSubjectList(response.data.data);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  return (<React.Fragment>
    <Header2 />
    <div className={`${styles.Topic_TestsMainArea}`}>
      <p className={`${styles.Topic_TestsTitleSmall}`}>Topic Specific tests</p>
      <p className={`${styles.Topic_TestsTitleBig}`}>Master any topic from our pool of tests</p>
      <div className={`${styles.Topic_TestsCusRow}`}>
        <div className={`${styles.margin_right}`}>
          <Select options={boardDDList} placeholder={'Board'} value={defaultBoard} onChange={cngBoard} />
        </div>
        <div className={`${styles.margin_right}`}>
          <Select options={classDDList} placeholder={'Class'} value={defaultClass} onChange={cngClass} />
        </div>
        <div className={`${styles.margin_right}`}>
          <Select options={subjectDDList} placeholder={'Subject'} value={defaultSubject} onChange={cngSubject} />
        </div>
      </div>
    </div>
    <div className={`${styles.Container}`}>
      <div className={`${styles.Row}`}>
        {subjectList.map((item, index) => {
          return (<div className={`${styles.SubjectHalf}`} key={index}>
            <div className={`${styles.SubjectCont} ${colorClasses1[index % 4]}`}>
              <div className={`${styles.subj_CircleSec}`}>
                <div className={`${styles.the_Circle}  ${colorClasses2[index % 4]}`}></div>
                <p className={`${styles.subj_Name}`}>{item.subject.title}</p>
              </div>
              <table>
                <tr onClick={goToList.bind(this, 'all', item.subject._id)} style={{ 'cursor': 'pointer' }}>
                  <th className={`${styles.th_HedingCustom}`}>All Chapter</th>
                </tr>
                {item.topics.map((t, tindex) => {
                  return (<tr key={tindex} onClick={goToList.bind(this, t._id, item.subject._id)} style={{ 'cursor': 'pointer' }}>
                    <th className={`${styles.th_HedingCustom}`}>Chapter {t.topic_index}: {t.title}</th>
                  </tr>);
                })}
                <tr>
                  <th className={`${styles.td_ColorBlu}`}><Link to={'/student/explore-tests/' + item.subject._id}>See All {item.subject.title}</Link></th>
                </tr>
              </table>
            </div>
          </div>);
        })}
      </div>
    </div>
  </React.Fragment>);
}

export default withStudentAuth(ExploreTest);