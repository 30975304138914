import React, { useEffect, useState } from 'react';
import styles from './quiz.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import { useNavigate, useParams } from 'react-router-dom';
import * as moment from 'moment';
import Header2 from '../../components/header2';
import CopyToClipboard from 'react-copy-to-clipboard';

const Quiz = () => {
    const navigate = useNavigate();
    const auth = authData();
    const { id } = useParams();

    const [quizDetails, setQuizDetails] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/quiz-details/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            let quizData = response.data.data;
            if (quizData.length > 0) {
                setQuizDetails(quizData[0]);
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token, id]);

    const startTest = () => {
        localStorage.setItem("quizOptions", JSON.stringify([]));
        localStorage.removeItem("quizTime");
        navigate('/student/quiz-details/' + id);
    }

    return (<React.Fragment>
        <div className={`${styles.background_Color}`}>
            <Header2 />
            <div className={`${styles.Container}`}>
                <div className={`${styles.main_DivSec}`}>
                    <div className={`${styles.mainDivSec_Smalldiv}`}>
                        <div className={`${styles.textArea}`}>
                            <p className={`${styles.classHader}`}>{quizDetails?.class?.title}</p>
                            <p className={`${styles.titleForHeding}`}>{quizDetails?.topic?.title}</p>
                            <p className={`${styles.Subject}`}>{quizDetails?.subject?.title}</p>
                            <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                        </div>
                        <div className={`${styles.buttomArea}`}>
                            {/*<button className={`${styles.share_Examlink}`}>Share Exam link</button>*/}
                            <CopyToClipboard text={window.location.href}>
                                <button className={`${styles.copy_Examlink}`}><i data-feather="link"></i>Copy Exam link</button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className={`${styles.mainDivSec_Bigdiv}`}>
                        <p className={`${styles.details_AreaHeding}`}>Details</p>
                        <div className={`${styles.details_Area}`}>
                            <div className={`${styles.details_AreaClassSec}`}>
                                <p className={`${styles.ClassSecHeading}`}>Class : <span>{quizDetails?.class?.title}</span></p>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <img src="/static/img/Vector.svg" className={`${styles.massgLogo}`} alt='' />
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>No: of Questions</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.total_question}</p>
                                    </div>
                                </div>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <div className={`${styles.incosCK}`}>
                                        <i data-feather="check"></i>
                                    </div>
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>Total Marks</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.total_marks}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.details_AreaClassSec}`}>
                                <p className={`${styles.ClassSecHeading}`}>Board : <span>{quizDetails?.board?.title}</span></p>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <img src="/static/img/Vectortime.svg" className={`${styles.massgLogo}`} alt='' />
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>Time allowed</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{(moment.duration(quizDetails?.time_limit, 'minutes').hours() > 0) && moment.duration(quizDetails?.time_limit, 'minutes').hours() + ' hr(s)'} {(moment.duration(quizDetails?.time_limit, 'minutes').minutes() > 0) && moment.duration(quizDetails?.time_limit, 'minutes').minutes() + ' min(s)'}</p>
                                    </div>
                                </div>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <div className={`${styles.incosMS}`}>
                                        <i data-feather="menu"></i>
                                    </div>
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>Level</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.level}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th className={`${styles.moreDetailsText}`}>Quiz Opening Time</th>
                                <th className={`${styles.moreDetailsMonth}`}>{quizDetails?.start_date ? moment(quizDetails?.start_date).format('Do MMMM YYYY') : '-'}</th>
                                <th className={`${styles.moreDetailsTime}`}>{quizDetails?.start_date ? moment(quizDetails?.start_date).format('hh:mm A') : '-'}</th>
                            </tr>
                            <tr>
                                <th className={`${styles.moreDetailsText}`}>Quiz Closing Time</th>
                                <th className={`${styles.moreDetailsMonth}`}>{quizDetails?.end_date ? moment(quizDetails?.end_date).format('Do MMMM YYYY') : '-'}</th>
                                <th className={`${styles.moreDetailsTime}`}>{quizDetails?.end_date ? moment(quizDetails?.end_date).format('hh:mm A') : '-'}</th>
                            </tr>
                        </table>
                        <button className={`${styles.EndBU}`} type='button' onClick={startTest}>Start your test</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(Quiz);