import React from 'react';
import styles from './login.module.css';
import { Tab, Tabs, styled } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-smart-toaster';
import { StorageGetItem, StorageSetItem } from '../../../admin/components/StorageComp';

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #909090',
  '& .MuiTabs-indicator': {
    backgroundColor: '#FF6B00',
    borderBottom: '1px solid #FF6B00',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '13px',
    color: '909090',
    '&.Mui-selected': {
      color: '#000',
    },
  }),
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <div className={`${styles.tabs_content}`}>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Login = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);
  const [sError, setsError] = React.useState('');
  const [mError, setmError] = React.useState('');
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setsError('');
    setmError('');
  };

  useEffect(() => {
    const authDataAce = StorageGetItem("authDataAceNew");
    if (authDataAce) {
      const authDataAceArr = JSON.parse(authDataAce);
      if (authDataAceArr.role === 'Admin') {
        navigate('/admin/user/list');
      } else if (authDataAceArr.role === 'Mentor') {
        navigate('/mentor/dashboard');
      } else if (authDataAceArr.role === 'Student') {
        navigate('/student/dashboard');
      }
    }
  }, [navigate]);

  const { handleSubmit: handleStudentSubmit, register: registerStudent, formState: { errors: sErrors } } = useForm();
  const { handleSubmit: handleTeacherSubmit, register: registerTeacher, formState: { errors: mErrors } } = useForm();

  const onStudentSubmit = (data) => {
    setsError('');
    axios.post(process.env.REACT_APP_API_URL + 'login/student', data).then(response => {
      StorageSetItem("authDataAceNew", JSON.stringify(response.data.data), 24 * 60 * 60 * 1000);
      navigate('/student/dashboard');
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        setsError(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  const onTeacherSubmit = (data) => {
    setmError('');
    axios.post(process.env.REACT_APP_API_URL + 'login/mentor', data).then(response => {
      StorageSetItem("authDataAceNew", JSON.stringify(response.data.data), 24 * 60 * 60 * 1000);
      navigate('/mentor/dashboard');
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        setmError(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  return (<React.Fragment>
    <div className={`${styles.login_MainDiv}`}>
      <div className={`${styles.login_BannerSec}`}>
        <img src='/static/img/Get_started_page_image.jpg' alt='' />
      </div>
      <div className={`${styles.login_FormSec}`}>
        <div className={`${styles.login_LohinForm}`}>
          <h2 className={`${styles.login_FormTitle} ${styles.Heading}`}>Login</h2>
          <div className={`${styles.tabs}`}>
            <StyledTabs value={tabValue} onChange={handleTabChange}>
              <StyledTab label="Login as Student" {...a11yProps(0)} />
              <StyledTab label="Login as Teacher" {...a11yProps(1)} />
            </StyledTabs>
            <CustomTabPanel value={tabValue} index={0}>
              <form onSubmit={handleStudentSubmit(onStudentSubmit)}>
                {(sError !== '') && <p className={`${styles.login_ErrorM}`}>{sError}</p>}
                <div className={`${styles.login_FormRow}`}>
                  <div className={`${styles.login_FormFieldHalf}`}>
                    <span className={`${styles.details}`}>Email</span>
                    <input type="email" className={`${styles.login_FormControl}`} placeholder="Email" {...registerStudent("email", {
                      required: "This field is required",
                      validate: {
                        matchPattern: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email address must be a valid address",
                      },
                    })} />
                    {sErrors?.email && <p className={`${styles.login_ErrorM}`}>{sErrors?.email?.message}</p>}
                  </div>
                  <div className={`${styles.login_FormFieldHalf}`}>
                    <span className={`${styles.details}`}>Password</span>
                    <input type="password" {...registerStudent("password", { required: 'This field is required' })} className={`${styles.login_FormControl}`} placeholder="Password" />
                    {sErrors?.password && <p className={`${styles.login_ErrorM}`}>{sErrors?.password?.message}</p>}
                  </div>
                  <div className={`${styles.buttonGroup}`}>
                    <input type="submit" value="Login" className={`${styles.loginButton}`} />
                    <Link to={'/student/sign-up'} className={`${styles.signUpButton}`}>Sign up</Link>
                  </div>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <form onSubmit={handleTeacherSubmit(onTeacherSubmit)}>
                {(mError !== '') && <p className={`${styles.login_ErrorM}`}>{mError}</p>}
                <div className={`${styles.login_FormRow}`}>
                  <div className={`${styles.login_FormFieldHalf}`}>
                    <span className={`${styles.details}`}>Email</span>
                    <input type="email" className={`${styles.login_FormControl}`} placeholder="Email" {...registerTeacher("email", {
                      required: "This field is required",
                      validate: {
                        matchPattern: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email address must be a valid address",
                      },
                    })} />
                    {mErrors?.email && <p className={`${styles.login_ErrorM}`}>{mErrors?.email?.message}</p>}
                  </div>
                  <div className={`${styles.login_FormFieldHalf}`}>
                    <span className={`${styles.details}`}>Password</span>
                    <input type="password" {...registerTeacher("password", { required: "This field is required" })} className={`${styles.login_FormControl}`} placeholder="Password" />
                    {mErrors?.password && <p className={`${styles.login_ErrorM}`}>{mErrors?.password?.message}</p>}
                  </div>
                  {/*<a href="#"><p className={`${styles.Forgot_PasswordArea}`}>Forgot Password ?</p></a>*/}
                  <div className={`${styles.buttonGroup}`}>
                    <input type="submit" value="Login" className={`${styles.loginButton}`} />
                    <Link to={'/mentor/sign-up'} className={`${styles.signUpButton}`}>Sign up</Link>
                  </div>
                </div>
              </form>
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default Login;