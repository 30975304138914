import ContentLoader from "react-content-loader";

export const TableLoader = () => (
  <ContentLoader>
    <rect x="0" y="17" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="127" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="237" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="347" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="457" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="567" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="677" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="787" rx="4" ry="3" width="1800" height="90"></rect>
    <rect x="0" y="897" rx="4" ry="3" width="1800" height="90"></rect>
  </ContentLoader>
);

export const TableLoaderSmall = () => (
  <ContentLoader>
    <rect x="0" y="17" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="97" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="177" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="257" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="337" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="417" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="497" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="577" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="657" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="737" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="817" rx="4" ry="3" width="1800" height="60"></rect>
    <rect x="0" y="897" rx="4" ry="3" width="1800" height="60"></rect>
  </ContentLoader>
);

export const FromLoader = () => (
  <ContentLoader>
    <rect x="0px" y="17" rx="4" ry="3" width="300px" height="48"></rect>
    <rect x="330px" y="17" rx="4" ry="3" width="300px" height="48"></rect>

    <rect x="0px" y="82" rx="3" ry="3" width="300px" height="48"></rect>
    <rect x="330px" y="82" rx="3" ry="3" width="300px" height="48"></rect>

    <rect x="0px" y="147" rx="3" ry="3" width="300px" height="48"></rect>
    <rect x="330px" y="147" rx="3" ry="3" width="300px" height="48"></rect>

    <rect x="0px" y="212" rx="3" ry="3" width="630px" height="80"></rect>

    <rect x="0px" y="320" rx="3" ry="3" width="130px" height="48"></rect>
    <rect x="160px" y="320" rx="3" ry="3" width="130px" height="48"></rect>

    {/* <rect x="0px" y="160" rx="4" ry="3" width="110px" height="8"></rect>
    <rect x="130px" y="160" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="330" y="160" rx="4" ry="3" width="70px" height="8"></rect>
    <rect x="0px" y="187" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="120px" y="187" rx="3" ry="3" width="150px" height="8"></rect>
    <rect x="290px" y="187" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="0px" y="220" rx="3" ry="3" width="2000px" height="40"></rect>

    <rect x="0px" y="315" rx="4" ry="3" width="110px" height="8"></rect>
    <rect x="130px" y="315" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="330" y="315" rx="4" ry="3" width="70px" height="8"></rect>
    <rect x="0px" y="343" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="120px" y="343" rx="3" ry="3" width="150px" height="8"></rect>
    <rect x="290px" y="343" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="0px" y="370" rx="3" ry="3" width="2000px" height="40"></rect> */}
  </ContentLoader>
);

export const TabLoader = () => (
  <ContentLoader>
    <rect x="0px" y="" rx="4" ry="3" width="150px" height="15"></rect>
    <rect x="0" y="27" rx="4" ry="3" width="800" height="2"></rect>

    <rect x="0" y="55" rx="3" ry="3" width="200px" height="8"></rect>
    <rect x="0px" y="77" rx="3" ry="3" width="140px" height="8"></rect>
    <rect x="160px" y="77" rx="3" ry="3" width="240px" height="8"></rect>

    <rect x="0" y="115" rx="3" ry="3" width="250" height="8"></rect>
    <rect x="0px" y="137" rx="3" ry="3" width="160" height="8"></rect>
    <rect x="180" y="137" rx="3" ry="3" width="280" height="8"></rect>
  </ContentLoader>
);

export const LatestArticleListLoader = () => (
  <ContentLoader>
    <rect x="0" y="0" rx="4" ry="3" width="500" height="15"></rect>
  </ContentLoader>
);

export const FAQCataListLoader = () => (
  <ContentLoader>
    <rect x="0px" y="17" rx="4" ry="3" width="80px" height="8" />
    <rect x="130px" y="17" rx="4" ry="3" width="180px" height="8" />
    <rect x="330" y="17" rx="4" ry="3" width="70px" height="8" />
    <rect x="0px" y="42" rx="3" ry="3" width="100px" height="8" />
    <rect x="160px" y="42" rx="3" ry="3" width="100px" height="8" />
    <rect x="290px" y="42" rx="3" ry="3" width="100px" height="8" />
    <rect x="0px" y="67" rx="3" ry="3" width="110px" height="8" />
    <rect x="160px" y="67" rx="3" ry="3" width="1200px" height="8" />
    <rect x="0px" y="92" rx="3" ry="3" width="400px" height="8" />
    <rect x="430px" y="92" rx="3" ry="3" width="1200px" height="8" />

    <rect x="0px" y="167" rx="4" ry="3" width="80px" height="8" />
    <rect x="130px" y="167" rx="4" ry="3" width="180px" height="8" />
    <rect x="330" y="167" rx="4" ry="3" width="70px" height="8" />
    <rect x="0px" y="192" rx="3" ry="3" width="100px" height="8" />
    <rect x="160px" y="192" rx="3" ry="3" width="100px" height="8" />
    <rect x="290px" y="192" rx="3" ry="3" width="100px" height="8" />
    <rect x="0px" y="217" rx="3" ry="3" width="110px" height="8" />
    <rect x="160px" y="217" rx="3" ry="3" width="1200px" height="8" />
    <rect x="0px" y="242" rx="3" ry="3" width="400px" height="8" />
    <rect x="430px" y="242" rx="3" ry="3" width="1200px" height="8" />

    <rect x="0px" y="317" rx="4" ry="3" width="80px" height="8" />
    <rect x="130px" y="317" rx="4" ry="3" width="180px" height="8" />
    <rect x="330" y="317" rx="4" ry="3" width="70px" height="8" />
    <rect x="0px" y="342" rx="3" ry="3" width="100px" height="8" />
    <rect x="160px" y="342" rx="3" ry="3" width="100px" height="8" />
    <rect x="290px" y="342" rx="3" ry="3" width="100px" height="8" />
    <rect x="0px" y="367" rx="3" ry="3" width="110px" height="8" />
    <rect x="160px" y="367" rx="3" ry="3" width="1200px" height="8" />
    <rect x="0px" y="392" rx="3" ry="3" width="400px" height="8" />
    <rect x="430px" y="392" rx="3" ry="3" width="1200px" height="8" />
  </ContentLoader>
);

export const HeaderLoader = () => (
  <ContentLoader>
    <rect x="0" y="8" rx="4" ry="3" width="250" height="15"></rect>
  </ContentLoader>
);

export const DetailsLoader = () => (
  <ContentLoader>
    <rect x="0px" y="17" rx="4" ry="3" width="110px" height="8"></rect>
    <rect x="130px" y="17" rx="4" ry="3" width="680px" height="8"></rect>
    <rect x="830" y="17" rx="4" ry="3" width="250px" height="8"></rect>
    <rect x="0px" y="42" rx="3" ry="3" width="340px" height="8"></rect>
    <rect x="360px" y="42" rx="3" ry="3" width="540px" height="8"></rect>
    <rect x="920px" y="42" rx="3" ry="3" width="160px" height="8"></rect>
    <rect x="0px" y="67" rx="3" ry="3" width="140px" height="8"></rect>
    <rect x="160px" y="67" rx="3" ry="3" width="1200px" height="8"></rect>
    <rect x="0px" y="92" rx="3" ry="3" width="400px" height="8"></rect>
    <rect x="430px" y="92" rx="3" ry="3" width="1200px" height="8"></rect>
    <rect x="0px" y="117" rx="4" ry="3" width="110px" height="8"></rect>
    <rect x="130px" y="117" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="330" y="117" rx="4" ry="3" width="250px" height="8"></rect>
    <rect x="0px" y="142" rx="3" ry="3" width="140px" height="8"></rect>
    <rect x="160px" y="142" rx="3" ry="3" width="610" height="8"></rect>
    <rect x="790px" y="142" rx="3" ry="3" width="300" height="8"></rect>
    <rect x="0px" y="167" rx="3" ry="3" width="110px" height="8"></rect>
    <rect x="160px" y="167" rx="3" ry="3" width="1200px" height="8"></rect>
    <rect x="0px" y="192" rx="3" ry="3" width="400px" height="8"></rect>
    <rect x="430px" y="192" rx="3" ry="3" width="1200px" height="8"></rect>
    <rect x="0px" y="247" rx="4" ry="3" width="120" height="8"></rect>
    <rect x="130px" y="247" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="330" y="247" rx="4" ry="3" width="70px" height="8"></rect>
    <rect x="0px" y="272" rx="3" ry="3" width="150" height="8"></rect>
    <rect x="160px" y="272" rx="3" ry="3" width="100" height="8"></rect>
    <rect x="290px" y="272" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="0px" y="297" rx="3" ry="3" width="110px" height="8"></rect>
    <rect x="160px" y="297" rx="3" ry="3" width="1200px" height="8"></rect>
    <rect x="0px" y="322" rx="3" ry="3" width="400px" height="8"></rect>
    <rect x="430px" y="322" rx="3" ry="3" width="1200px" height="8"></rect>
    <rect x="0px" y="347" rx="4" ry="3" width="110px" height="8"></rect>
    <rect x="130px" y="347" rx="4" ry="3" width="680px" height="8"></rect>
    <rect x="830" y="347" rx="4" ry="3" width="250px" height="8"></rect>
  </ContentLoader>
);

export const ImgTabLoader = () => (
  <ContentLoader>
    <rect x="0px" y="0" rx="4" ry="3" width="500" height="150"></rect>
    <rect x="0px" y="175" rx="4" ry="3" width="110" height="8"></rect>
    <rect x="130px" y="175" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="330" y="175" rx="4" ry="3" width="70px" height="8"></rect>
    <rect x="0px" y="200" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="120" y="200" rx="3" ry="3" width="150" height="8"></rect>
    <rect x="290px" y="200" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="0px" y="226" rx="3" ry="3" width="140" height="8"></rect>
    <rect x="160px" y="226" rx="3" ry="3" width="260" height="8"></rect>
    <rect x="0px" y="264" rx="4" ry="3" width="80px" height="8"></rect>
    <rect x="100" y="264" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="300" y="264" rx="4" ry="3" width="70px" height="8"></rect>
    <rect x="0px" y="290" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="120" y="290" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="240" y="290" rx="3" ry="3" width="100px" height="8"></rect>
  </ContentLoader>
);

export const CardLoader = () => (
  <ContentLoader>
    <rect x="0px" y="" rx="4" ry="3" width="150px" height="15"></rect>
    <rect x="0" y="27" rx="4" ry="3" width="800" height="2"></rect>
    <rect x="0" y="42" rx="3" ry="3" width="200px" height="8"></rect>
    <rect x="0px" y="67" rx="3" ry="3" width="140px" height="8"></rect>
    <rect x="160px" y="67" rx="3" ry="3" width="240px" height="8"></rect>
    <rect x="0px" y="92" rx="4" ry="3" width="100px" height="8"></rect>
    <rect x="130px" y="92" rx="4" ry="3" width="180px" height="8"></rect>
    <rect x="0" y="117" rx="4" ry="3" width="250px" height="8"></rect>
    <rect x="0px" y="142" rx="3" ry="3" width="140px" height="8"></rect>
    <rect x="160px" y="142" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="280px" y="142" rx="3" ry="3" width="100px" height="8"></rect>
  </ContentLoader>
);

export const UploadLoader = () => (
  <ContentLoader>
    <rect x="0px" y="" rx="4" ry="3" width="800px" height="95"></rect>
    <rect x="0" y="117" rx="4" ry="3" width="250px" height="8"></rect>
    <rect x="0px" y="142" rx="3" ry="3" width="140px" height="8"></rect>
    <rect x="160px" y="142" rx="3" ry="3" width="100px" height="8"></rect>
    <rect x="280px" y="142" rx="3" ry="3" width="100px" height="8"></rect>
  </ContentLoader>
);

export const CommunicationLoaderS = () => (
  <ContentLoader>
    <rect x="0" y="17" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="28" rx="4" ry="3" width="100%" height="20"></rect>

    <rect x="0" y="97" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="108" rx="4" ry="3" width="100%" height="20"></rect>

    <rect x="0" y="187" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="198" rx="4" ry="3" width="100%" height="20"></rect>

    <rect x="0" y="277" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="288" rx="4" ry="3" width="100%" height="20"></rect>

    <rect x="0" y="367" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="378" rx="4" ry="3" width="100%" height="20"></rect>
  </ContentLoader>
);

export const CommunicationLoaderB = () => (
  // <div className={`${styles.ConLoaderS}`}>
  <ContentLoader>
    <rect x="15" y="17" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="22" rx="4" ry="3" width="250" height="40"></rect>

    <rect x="75%" y="97" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="80%" y="102" rx="4" ry="3" width="250" height="40"></rect>

    <rect x="15" y="187" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="192" rx="4" ry="3" width="250" height="40"></rect>

    <rect x="75%" y="277" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="80%" y="282" rx="4" ry="3" width="250" height="40"></rect>

    <rect x="15" y="367" rx="50" ry="50" width="50" height="50"></rect>
    <rect x="75" y="372" rx="4" ry="3" width="250" height="40"></rect>
  </ContentLoader>
  // </div>
);
