import React from 'react';
import styles from './creategroup.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { authData } from '../../components/getAuth';
import { toast } from 'react-smart-toaster';

const CreateTestStep1 = () => {
  const auth = authData();
  const navigate = useNavigate();

  const { handleSubmit, register, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    if (data?.board_id?.value) {
      data = { ...data, board_id: data?.board_id?.value };
    }
    if (data?.class_id?.value) {
      data = { ...data, class_id: data?.class_id?.value };
    }
    data = { ...data, status: 1 };
    axios.post(process.env.REACT_APP_API_URL + 'mentor/create-group', data, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/dashboard');
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.create_AccomplishmentSec}`}>
          <p>Create Group</p>
        </div>
        <div className={`${styles.general_FormBoxArea}`}>
          <div className={`${styles.general_TextAroSec}`}>
            <p className={`${styles.general_TextAroSecTitel}`}>General</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Classes</label>
              <input type="text" placeholder="Name of Classes" className={`${styles.accomplishment_inputTy}`} {...register("classes", { required: 'This field is required' })} />
              {errors?.title && <p className={`${styles.login_ErrorM}`}>{errors?.title?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Name</label>
              <input type="text" placeholder="Name of Group" className={`${styles.accomplishment_inputTy}`} {...register("title", { required: 'This field is required' })} />
              {errors?.title && <p className={`${styles.login_ErrorM}`}>{errors?.title?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Description</label>
              <textarea placeholder="Description" className={`${styles.FormTextInputArea}`} {...register("description", { required: 'This field is required' })}></textarea>
              {errors?.description && <p className={`${styles.login_ErrorM}`}>{errors?.description?.message}</p>}
            </div>
            <div className={`${styles.ending_SaveSec}`}>
              <button type='submit' className={`${styles.ending_SaveBU}`}>Create Group</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(CreateTestStep1);