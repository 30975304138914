import React, { useEffect, useState } from 'react';
import styles from './step2.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import * as moment from 'moment';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateTestStep2 = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { handleSubmit, register, formState: { errors }, control, setValue, getValues } = useForm({
    defaultValues: { date_range: 'No', restriction: 'Public' }
  });
  const { id } = useParams();
  const [isDateRange, setIsDateRange] = useState(false);
  const [isRestriction, setIsRestriction] = useState(false);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'mentor/test/' + id, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      let testData = response.data.data;
      if (testData?.time_limit) {
        [{ label: '00:30 H', value: 30 }, { label: '01:00 H', value: 60 }, { label: '01:30 H', value: 90 }, { label: '02:00 H', value: 120 }, { label: '02:30 H', value: 150 }, { label: '03:00 H', value: 180 }].forEach(item => {
          if (item.value === testData?.time_limit) {
            setValue('time_limit', item);
          }
        })
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, [id, auth?.token, setValue]);

  const onSubmit = (data) => {
    let postData = { time_limit: data?.time_limit?.value };
    if (data?.date_range === 'Yes') {
      postData = { ...postData, start_date: moment(data.start_date).format('YYYY-MM-DD HH:mm:ss'), end_date: moment(data.end_date).format('YYYY-MM-DD HH:mm:ss') };
    } else {
      postData = { ...postData, start_date: null, end_date: null };
    }
    if (data?.restriction === 'Private') {
      postData = { ...postData, is_restrict: true, quiz_code: data?.join_code, quiz_password: data?.join_password };
    } else {
      postData = { ...postData, is_restrict: false };
    }

    axios.put(process.env.REACT_APP_API_URL + 'mentor/update-test/' + id, postData, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      navigate('/mentor/create-test/step-3/' + id);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  const generateCode = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 10) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setValue('join_code', result);
  }

  return (<React.Fragment>

    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.AccomplishmentSec}`}>
          <p>Create Test</p>
        </div>
        <div className={`${styles.FormBoxArea}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.chackBox_BUSec}`}>
              <div className={`${styles.InputChackGrop}`}>
                <p className={`${styles.checkBoxLabel}`}>Time Limit</p>
              </div>
              <div className={`${styles.dateInput_Under1}`}>
                <Controller control={control} {...register('time_limit', { required: 'This field is required' })} render={({ field }) => (
                  <Select {...field} options={[{ label: '00:30 H', value: 30 }, { label: '01:00 H', value: 60 }, { label: '01:30 H', value: 90 }, { label: '02:00 H', value: 120 }, { label: '02:30 H', value: 150 }, { label: '03:00 H', value: 180 }]} />
                )} />
              </div>
              {errors?.time_limit && <p className={`${styles.login_ErrorM}`}>{errors?.time_limit?.message}</p>}
              <div className={`${styles.InputChackGrop}`}>
                <p className={`${styles.checkBoxLabel}`}>Date Range</p>
              </div>
              <div className={`${styles.dateInput_Under1}`}>
                <Controller
                  {...register('date_range')}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} onChange={e => { field.onChange(e); (e.target.value === 'Yes') ? setIsDateRange(true) : setIsDateRange(false); }}>
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    </RadioGroup>
                  )}
                />
              </div>
              {isDateRange && <>
                <div className={`${styles.InputChackGrop}`}>
                  <p className={`${styles.checkBoxLabel}`}>Start Date</p>
                </div>
                <div className={`${styles.dateInput_Sec}`}>
                  <div className={`${styles.dateInput_Under}`}>
                    <Controller control={control} {...register('start_date', { required: 'This field is required' })} render={({ field: { ref, ...field } }) => (
                      <DatePicker {...field} inputRef={ref} selected={field.value} showTimeSelect dateFormat="dd/MM/yyyy h:mm aa" autoComplete='off' className={`${styles.TextTy}`} />
                    )} />
                    {errors?.start_date && <p className={`${styles.login_ErrorM}`}>{errors?.start_date?.message}</p>}
                  </div>
                </div>
                <div className={`${styles.InputChackGrop}`}>
                  <p className={`${styles.checkBoxLabel}`}>End Date</p>
                </div>
                <div className={`${styles.dateInput_Sec}`}>
                  <div className={`${styles.dateInput_Under}`}>
                    <Controller control={control} {...register('end_date', { required: 'This field is required' })} render={({ field: { ref, ...field } }) => (
                      <DatePicker {...field} inputRef={ref} selected={field.value} showTimeSelect dateFormat="dd/MM/yyyy h:mm aa" autoComplete='off' className={`${styles.TextTy}`} />
                    )} />
                    {errors?.end_date && <p className={`${styles.login_ErrorM}`}>{errors?.end_date?.message}</p>}
                  </div>
                </div>
              </>}
              <div className={`${styles.InputChackGrop}`}>
                <p className={`${styles.checkBoxLabel}`}>Restriction</p>
              </div>
              <div className={`${styles.dateInput_Under1}`}>
                <Controller
                  {...register('restriction')}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} onChange={e => { field.onChange(e); (e.target.value === 'Private') ? setIsRestriction(true) : setIsRestriction(false); }}>
                      <FormControlLabel value="Public" control={<Radio />} label="Public" />
                      <FormControlLabel value="Private" control={<Radio />} label="Private" />
                    </RadioGroup>
                  )}
                />
              </div>
              {isRestriction && <>
                <div className={`${styles.InputChackGrop}`}>
                  <p className={`${styles.checkBoxLabel}`}>Join Code</p>
                </div>
                <div className={`${styles.code_ButtomGroup}`}>
                  <input type="text" placeholder="Quiz code" {...register('join_code', { required: 'This field is required' })} className={`${styles.TextTy}`} autoComplete='off' />
                  <button type='button' className={`${styles.chackBoxButton}`} onClick={generateCode}>Generate Code</button>
                </div>
                {errors?.join_code && <p className={`${styles.login_ErrorM}`}>{errors?.join_code?.message}</p>}
                <div>
                  <div>
                    <label>Password</label>
                    <input type="password" placeholder="Enter password" className={`${styles.inputPW}`} autoComplete='off' {...register('join_password', {
                      required: 'This field is required',
                      minLength: {
                        value: 6,
                        message: 'Password sholuld be 6 characters'
                      }
                    })} />
                    {errors?.join_password && <p className={`${styles.login_ErrorMT}`}>{errors?.join_password?.message}</p>}
                    <input type="password" placeholder="Re-enter password" className={`${styles.inputPW}`} autoComplete='off' {...register('join_conf_password', {
                      required: 'This field is required',
                      validate: value => value === getValues('join_password') || "The passwords do not match"
                    })} />
                    {errors?.join_conf_password && <p className={`${styles.login_ErrorM}`}>{errors?.join_conf_password?.message}</p>}
                  </div>
                </div>
              </>}
            </div>
            <div className={`${styles.SaveSec}`}>
              <button className={`${styles.SaveBU}`}>Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(CreateTestStep2);