import React, { useState, useEffect } from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs, Button } from '@mui/material';
import { useNavigate, Link, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import Select from "react-select";
import { authData } from "../../components/getAuth";

function TopicAdd() {
  const auth = authData();
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [boardList, setBoardList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedData, setSelectedData] = useState({ board: '', class: '' });
  const [itemDet, setItemDet] = useState({});

  let schema = yup.object().shape({
    topic_index: yup.string().required('This field is Required'),
    title: yup.string().required('This field is Required'),
    board_id: yup.object().required('This field is Required'),
    class_id: yup.object().required('This field is Required'),
    subject_id: yup.object().required('This field is Required'),
  });

  const { register, handleSubmit, watch, setValue, formState: { errors }, control } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/boards').then(response => {
      let boardListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setBoardList(boardListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setClassList(classListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data;
      setAllSubjectList(subjectListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    if (selectedData?.board !== '' && selectedData?.class !== '') {
      let subjectListTemp = allSubjectList.filter(i => (i.boards.indexOf(selectedData?.board) > -1 && i.classes.indexOf(selectedData?.class) > -1)).map(i => { return { value: i._id, label: i.title }; });
      setSubjectList(subjectListTemp);
      subjectListTemp?.forEach(item => {
        if (item.value === itemDet.subject_id) {
          setValue('subject_id', item);
        }
      });
    }
  }, [allSubjectList, selectedData, itemDet.subject_id, setValue])

  useEffect(() => {
    if (!isAddMode) {
      axios.get(process.env.REACT_APP_API_URL + 'topics/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setContentLoading(false);
        let itemData = response.data.data;
        setItemDet(itemData);
        setValue('topic_index', itemData.topic_index);
        setValue('title', itemData.title);
        setValue('description', itemData.description);
        setSelectedData({ board: itemData.board_id, class: itemData.class_id });
        boardList?.forEach(item => {
          if (item.value === itemData.board_id) {
            setValue('board_id', item);
          }
        });
        classList?.forEach(item => {
          if (item.value === itemData.class_id) {
            setValue('class_id', item);
          }
        });
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
  }, [isAddMode, id, setValue, boardList, classList, auth?.token]);

  async function onSubmit(data) {
    data = { ...data, status: 1, board_id: data?.board_id?.value, class_id: data?.class_id?.value, subject_id: data?.subject_id?.value };

    const postData = data;

    setLoading(true);

    if (isAddMode) {
      return axios.post(process.env.REACT_APP_API_URL + 'topics', postData, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/topic/list');
      }).catch(error => {
        setLoading(false);
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    } else {
      return axios.put(process.env.REACT_APP_API_URL + 'topics/' + id, postData, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/topic/list');
      }).catch(error => {
        setLoading(false);
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
  }

  return (<React.Fragment>
    {loading && <Loader />}
    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>}
    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Topic {isAddMode ? 'Add' : 'Edit'}</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={'/admin/topic/list'}>Topics</Link>
          <Typography color="text.primary">{isAddMode ? 'Add' : 'Edit'}</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Chapter Index*</label>
                <input type='number' {...register("topic_index")} className={`${styles.FormControl}`} />
                {errors.topic_index && !watch().topic_index && <span className={`${styles.ErrorM}`}>{errors.topic_index.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Title*</label>
                <input {...register("title")} className={`${styles.FormControl}`} />
                {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Description</label>
                <input {...register("description")} className={`${styles.FormControl}`} />
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Board*</label>
                <Controller control={control} options={boardList} {...register('board_id')} render={({ field }) => (
                  <Select {...field} options={boardList} onChange={(e) => { field.onChange(e); setSelectedData({ ...selectedData, board: e.value }); }} />
                )} />
                {errors.board_id && !watch().board_id && <span className={`${styles.ErrorM}`}>{errors.board_id.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Class*</label>
                <Controller control={control} options={classList} {...register('class_id')} render={({ field }) => (
                  <Select {...field} options={classList} onChange={(e) => { field.onChange(e); setSelectedData({ ...selectedData, class: e.value }); }} />
                )} />
                {errors.class_id && !watch().class_id && <span className={`${styles.ErrorM}`}>{errors.class_id.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Subject*</label>
                <Controller control={control} options={subjectList} {...register('subject_id')} render={({ field }) => (
                  <Select {...field} options={subjectList} />
                )} />
                {errors.subject_id && !watch().subject_id && <span className={`${styles.ErrorM}`}>{errors.subject_id.message}</span>}
              </div>
            </div>

            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/topic/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}

export default withAdminAuth(TopicAdd);