import React from "react";
import Footer from "../admin/components/footer";
import Header from "../admin/components/header";

const AdminLayout = ({ children }) => {
  return (<React.Fragment>
    <div className="DashboardMainBody">
      <Header />
      {children}
    </div>
    <Footer />
  </React.Fragment>);
};

export default AdminLayout;