import React from 'react';
import styles from './Page404.module.css';

const Page404 = () => {

  return (<div className={`${styles.Error404}`}>
    <img src="/images/404.jpg" alt="" />
  </div>);
}

export default Page404;