import React, { useEffect, useState } from 'react';
import styles from './testlist.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import * as moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { X } from 'react-feather';
import DataTable from 'react-data-table-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Dashboard = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { group_id } = useParams();
  const [allTestList, setAllTestList] = useState([]);
  const [filteredTestList, setFilteredTestList] = useState([]);
  const [evaluteTestList, setEvaluteTestList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [delId, setDelId] = useState('');
  const [groupDet, setGroupDet] = useState(null);

  useEffect(() => {
    function getTests() {
      axios(process.env.REACT_APP_API_URL + 'mentor/tests-by-group/' + group_id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setAllTestList(response.data.data);
        setFilteredTestList(response.data.data);
        setEvaluteTestList(response.data.data?.filter(i => i?.not_evalute > 0 && i?.added_by === auth?._id));
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token && group_id) {
      getTests();
    }
  }, [auth?.token, group_id, auth?._id]);

  useEffect(() => {
    function getGroup() {
      axios(process.env.REACT_APP_API_URL + 'mentor/group/' + group_id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setGroupDet(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

    if (auth?.token && group_id) {
      getGroup();
    }
  }, [auth?.token, group_id]);

  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      width: '300px'
    },
    {
      name: 'Subject',
      cell: tableProps => (<div className={`${styles.title_VerticalFest_TD}`}>
        {tableProps?.subject?.title}
        {tableProps?.topic ? <p className={`${styles.smaLL_Ptag}`}>Chapter {tableProps?.topic?.topic_index}: {tableProps?.topic?.title}</p> : <p className={`${styles.smaLL_Ptag}`}>All Topics</p>}
      </div>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA?.subject?.title > rowB?.subject?.title) {
          return 1;
        }
        if (rowB?.subject?.title > rowA?.subject?.title) {
          return -1;
        }
        return 0;
      }
    },
    {
      name: 'Attemted',
      selector: row => row.total_attemted,
      sortable: true,
      width: '100px'
    },
    {
      name: 'End Date',
      selector: row => (row?.end_date) ? moment(row?.end_date).format('DD-MM-YYYY') : '-',
      sortable: true,
    },
    {
      name: 'Status',
      cell: tableProps => (
        (tableProps?.status === 1) ? (<span className={`${styles.colr_Green}`}>Published</span>) : <span className={`${styles.colr_Red}`}>Not Completed</span>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA?.status > rowB?.status) {
          return 1;
        }
        if (rowB?.status > rowA?.status) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: '',
      cell: tableProps => (<div>
        {(tableProps?.added_by === auth?._id && tableProps?.status === 0) && <><Link style={{ fontSize: '12px', fontWeight: 600, color: '#fff', padding: '8px', borderRadius: '5px', background: '#ff8414', marginRight: '3px' }} to={'/mentor/create-test/step-1/' + tableProps?._id}>Edit</Link><Button style={{ fontSize: '12px', fontWeight: 600, color: '#fff', padding: '5px', borderRadius: '5px', background: '#ff8414', textTransform: 'capitalize', marginBottom: '1px' }} onClick={deleteTest.bind(this, tableProps)}>Delete</Button></>}
      </div>),
      sortable: false
    }
  ];

  const columns2 = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      width: '300px'
    },
    {
      name: 'Subject',
      cell: tableProps => (<div className={`${styles.title_VerticalFest_TD}`}>
        {tableProps?.subject?.title}
        {tableProps?.topic ? <p className={`${styles.smaLL_Ptag}`}>Chapter {tableProps?.topic?.topic_index}: {tableProps?.topic?.title}</p> : <p className={`${styles.smaLL_Ptag}`}>All Topics</p>}
      </div>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA?.subject?.title > rowB?.subject?.title) {
          return 1;
        }
        if (rowB?.subject?.title > rowA?.subject?.title) {
          return -1;
        }
        return 0;
      }
    },
    {
      name: 'Attemted',
      selector: row => row.total_attemted,
      sortable: true,
      width: '100px'
    },
    {
      name: 'End Date',
      selector: row => (row?.end_date) ? moment(row?.end_date).format('DD-MM-YYYY') : '-',
      sortable: true,
    },
    {
      name: 'Status',
      cell: tableProps => (<span className={`${styles.colr_yellow}`}>Need Evalute</span>),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA?.status > rowB?.status) {
          return 1;
        }
        if (rowB?.status > rowA?.status) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: '',
      cell: tableProps => (<div><Link style={{ fontSize: '12px', fontWeight: 600, color: '#fff', padding: '8px', borderRadius: '5px', background: '#ff8414' }} to={'/mentor/test-evalute/' + tableProps?._id}>Evalute</Link></div>),
      sortable: false
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: '16px',
        color: '#4B4B4B',
        fontWeight: 600
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        color: '#191D63',
        fontWeight: 500
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFFFFF',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#E6ECF0',
        cursor: 'pointer'
      },
    },
  };

  const paginationComponentOptions = {
    noRowsPerPage: true
  };

  useEffect(() => {
    let filteredData = allTestList;

    if (filterText !== '') {
      filteredData = filteredData.filter((item) => {
        return item.title.toLowerCase().includes(filterText.toLowerCase()) || item.subject?.title.toLowerCase().includes(filterText.toLowerCase());
      });
    }

    setFilteredTestList(filteredData);
  }, [filterText, allTestList]);

  const deleteTest = (item) => {
    setDelId(item?._id);
    setOpen(true);
  }

  const handleClose = () => {
    setDelId('');
    setOpen(false);
  };

  const confirmDel = () => {
    setOpen(false);
    axios.delete(process.env.REACT_APP_API_URL + 'mentor/delete-test/' + delId, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      axios(process.env.REACT_APP_API_URL + 'mentor/tests', {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setAllTestList(response.data.data);
        setFilteredTestList(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.my_DashboardMainArea}`}>
          <div className={`${styles.my_DashboardNam}`}>
            <p className={`${styles.my_DashboarHeading}`}>Hi {auth?.first_name},</p>
            <p className={`${styles.my_DashboarSubHeading}`}>Test List of <strong>{groupDet?.title}</strong></p>
          </div>
        </div>
      </div>

      <div className={`${styles.Container}`}>
        <div className={`${styles.Row}`}>

          <div className={`${styles.BigDiv_Sec}`}>

            <div className={`${styles.text_ButtonGrop}`}>
              <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                <div className={`${styles.UnderSmLContGroup}`}>
                  <p className={`${styles.UnderSmLbigTitle}`}>Test List of {groupDet?.title}</p>
                </div>
              </div>
              <div className={`${styles.UnderSmLContGroup2}`}>
                <Link to={'/mentor/create-test'} className={`${styles.My_TestsBU}`}><img src="/static/img/plesicons.svg" alt="" />Create New Test</Link>
              </div>
            </div>

            <div className={`${styles.table_Sec}`}>
              <div className='TableFilterHead'>
                <div className="DataTableSearch" style={{ height: '38px' }}>
                  <TextField id="search" type="text" placeholder="Search by keyword" aria-label="Search Input" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                  {filterText && <Button type="button" className="CloseBU" onClick={(e) => setFilterText('')}><X /></Button>}
                </div>
              </div>
              <DataTable
                className={`${styles.table}`}
                columns={columns}
                data={filteredTestList}
                pagination
                customStyles={customStyles}
                paginationComponentOptions={paginationComponentOptions}
                onRowClicked={(e) => (e?.added_by === auth?._id ? navigate('/mentor/create-test/step-1/' + e?._id) : navigate('/mentor/view-test/' + e?._id))}
                highlightOnHover={true}
                noDataComponent={<div className={`${styles.noData_FoundSec}`}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' />
                    <p>No tests are available now</p>
                  </div>
                </div>}
              />
            </div>
          </div>

          <div className={`${styles.BigDiv_Sec}`}>

            <div className={`${styles.text_ButtonGrop}`}>
              <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                <div className={`${styles.UnderSmLContGroup}`}>
                  <p className={`${styles.UnderSmLbigTitle}`}>Need To Evalute</p>
                </div>
              </div>
            </div>

            <div className={`${styles.table_Sec}`}>
              <DataTable
                className={`${styles.table}`}
                columns={columns2}
                data={evaluteTestList}
                pagination
                customStyles={customStyles}
                paginationComponentOptions={paginationComponentOptions}
                onRowClicked={(e) => navigate('/mentor/test-evalute/' + e?._id)}
                highlightOnHover={true}
                noDataComponent={<div className={`${styles.noData_FoundSec}`}>
                  <div className={`${styles.noData_Found_SecUnder}`}>
                    <img src='/static/img/no-datafound.png' alt='' />
                    <p>No tests are available now</p>
                  </div>
                </div>}
              />
            </div>
          </div>

        </div>
      </div>
    </div>

    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{"Delete Test"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to delete this test?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>Cancel</Button>
        <Button onClick={confirmDel} autoFocus>Confirm</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>)
}

export default withMentorAuth(Dashboard);