import React, { useEffect, useState } from 'react';
import styles from './createquestion.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { authData } from '../../components/getAuth';
import Select from "react-select";
import Loader from '../../../admin/components/Loader';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const CreateQuestion = () => {
  const auth = authData();
  const navigate = useNavigate();
  const { handleSubmit, register, formState: { errors }, control, setValue } = useForm({
    defaultValues: { type: 'Single Select' }
  });
  const { id, qId } = useParams();
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [qType, setQType] = useState('');

  useEffect(() => {
    function getQuestionDetails() {
      axios(process.env.REACT_APP_API_URL + 'mentor/question/' + qId, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setValue('title', response.data.data.title);
        setValue('description', response.data.data.description);
        setValue('marks', response.data.data.marks);
        setValue('type', response.data.data.type);
        setValue('level', { label: response.data.data.level, value: response.data.data.level });
        if (response.data.data?.image && response.data.data?.image !== '') {
          setPreview(response.data.data?.image);
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
    if (qId) {
      getQuestionDetails();
    }

  }, [qId, auth?.token, setValue]);

  const onSubmit = (data) => {
    data = { ...data, level: data?.level?.value };
    if (data.type !== 'Subjective' && data.remark) {
      delete data.remark;
    }
    if (qId) {
      axios.put(process.env.REACT_APP_API_URL + 'mentor/update-question/' + qId, data, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        if (data.type === 'Subjective') {
          navigate('/mentor/create-test/step-3/' + id);
        } else {
          navigate('/mentor/create-test/set-answer/' + id + '/' + qId);
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    } else {
      data = { ...data, status: 1, test_id: id };
      axios.post(process.env.REACT_APP_API_URL + 'mentor/create-question', data, {
        headers: {
          'Content-Type': "multipart/form-data",
          'x-access-token': auth?.token
        }
      }).then(response => {
        let quesData = response.data.data;
        if (data.type === 'Subjective') {
          navigate('/mentor/create-test/step-3/' + id);
        } else {
          navigate('/mentor/create-test/set-answer/' + id + '/' + quesData?._id);
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
  }

  const handleFileChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(null)
      return;
    }
    setLoading(true);

    const postData = new FormData();
    postData.append('file', e.target.files[0]);

    axios.post(process.env.REACT_APP_API_URL + 'public/upload-file', postData, {
      headers: { 'x-access-token': auth?.token }
    }).then(response => {
      setLoading(false);
      setPreview(response.data.data.url);
      setValue('image', response.data.data.name);
    }).catch(error => {
      setLoading(false);
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  };

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      {loading && <Loader />}
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.MainDiv_Area}`}>
          <p className={`${styles.general_TextAroSecTitel}`}>General / Question</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Topic Title</label>
              <input placeholder="Title" {...register('title', { required: 'This field is required' })} className={`${styles.accomplishment_inputTy}`} />
              {errors?.title && <p className={`${styles.login_ErrorM}`}>{errors?.title?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Question</label>
              <textarea placeholder="Description" {...register('description', { required: 'This field is required' })} className={`${styles.accomplishment_inputTy}`} style={{ height: '70px' }}></textarea>
              {errors?.description && <p className={`${styles.login_ErrorM}`}>{errors?.description?.message}</p>}
            </div>
            <div className={`${styles.chackBox_BUSec}`}>
              <div className={`${styles.Tex_InputChackGrop}`}>
                <p className={`${styles.chackBox_Text}`}>Image</p>
              </div>
              <input style={{ 'display': 'none' }} id="file" type="file" name="file" accept="image/*" onChange={handleFileChange} />
              <label htmlFor="file" className={`${styles.chackBoxButton}`}>Add Image</label>
              {preview && <img src={preview} style={{ 'maxWidth': '300px', 'maxHeight': '150px' }} alt='' />}
            </div>
            <div className={`${styles.accomplishment_FormField_30}`}>
              <label className={`${styles.accomplishment_Label}`}>Level</label>
              <Controller control={control} {...register('level', { required: 'This field is required' })} render={({ field }) => (
                <Select {...field} options={[{ label: 'Easy', value: 'Easy' }, { label: 'Medium', value: 'Medium' }, { label: 'Hard', value: 'Hard' }]} className={`${styles.accomplishment_inputTy2}`} />
              )} />
              {errors?.level && <p className={`${styles.login_ErrorM}`}>{errors?.level?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField_30}`}>
              <label className={`${styles.accomplishment_Label}`}>Marks</label>
              <Controller control={control} {...register('marks', { required: 'This field is required' })} render={({ field }) => (
                <NumericFormat {...field} placeholder="Marks" className={`${styles.accomplishment_inputTy}`} />
              )} />
              {errors?.marks && <p className={`${styles.login_ErrorM}`}>{errors?.marks?.message}</p>}
            </div>
            <div className={`${styles.accomplishment_FormField_30}`}>
              <label className={`${styles.accomplishment_Label}`}>Type</label>
              <Controller
                {...register('type')}
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} onChange={(e) => { field.onChange(e); setQType(e.target.value); }}>
                    <FormControlLabel value="Single Select" control={<Radio />} label="Single Select" />
                    <FormControlLabel value="Muliple Select" control={<Radio />} label="Muliple Select" />
                    <FormControlLabel value="True/False" control={<Radio />} label="True/False" />
                    <FormControlLabel value="Subjective" control={<Radio />} label="Subjective" />
                  </RadioGroup>
                )}
              />
            </div>
            {(qType === 'Subjective') && <div className={`${styles.accomplishment_FormField}`}>
              <label className={`${styles.accomplishment_Label}`}>Remark</label>
              <textarea placeholder="Remark" {...register('remark', { required: 'This field is required' })} className={`${styles.accomplishment_inputTy}`} style={{ height: '70px' }}></textarea>
              {errors?.remark && <p className={`${styles.login_ErrorM}`}>{errors?.remark?.message}</p>}
            </div>}
            <div className={`${styles.ending_SaveSec}`}>
              <button className={`${styles.ending_SaveBU}`}>Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(CreateQuestion);