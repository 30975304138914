import React, { useEffect, useState } from 'react';
import styles from './mydashboard.module.css';
import withStudentAuth from '../../components/withStudentAuth';
import Header2 from '../../components/header2';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import * as moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const MyDashboard = () => {
    const navigate = useNavigate();
    const auth = authData();
    const [testList, setTestList] = useState([]);
    const [marksByClass, setMarksByClass] = useState([]);
    const [marksBySubject, setMarksBySubject] = useState([]);
    const [mySummary, setMySummary] = useState({});
    const [chartdata, setChartdata] = useState(null);
    const [classDDList, setClassDDList] = useState([]);
    const [subjectAllDDList, setSubjectAllDDList] = useState([]);
    const [subjectDDList, setSubjectDDList] = useState([]);
    const [defaultClass, setDefaultClass] = useState(null);
    const [defaultSubject, setDefaultSubject] = useState({ value: 'all', label: 'All Subjects' });

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
            let classListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
            setClassDDList([{ value: 'all', label: 'All Class' }].concat(classListTemp));
            setDefaultClass({ value: 'all', label: 'All Class' });
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, []);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
            setSubjectAllDDList(response.data.data);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, []);

    useEffect(() => {
        let subjectListTemp = subjectAllDDList;

        if (defaultClass?.value !== 'all') {
            subjectListTemp = subjectListTemp.filter(i => i.classes?.indexOf(defaultClass?.value) > -1);
        }

        subjectListTemp = subjectListTemp.map(i => { return { value: i._id, label: i.title }; });
        subjectListTemp = [{ value: 'all', label: 'All Subjects' }].concat(subjectListTemp);

        setSubjectDDList(subjectListTemp);
    }, [defaultClass, subjectAllDDList, auth?.student_info?.board_id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/my-class-marks', {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setMarksByClass(response.data.data);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/my-subject-marks', {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setMarksBySubject(response.data.data);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token]);

    useEffect(() => {
        setChartdata({
            labels: ['Hard', 'Medium', 'Easy'],
            datasets: [
                {
                    label: "Total Attempted",
                    backgroundColor: '#FF5C00',
                    data: [mySummary?.total_hard_attempted, mySummary?.total_medium_attempted, mySummary?.total_easy_attempted],
                    barPercentage: 0.4
                },
                {
                    label: "Total Correct",
                    backgroundColor: '#2EE700',
                    data: [mySummary?.total_hard_corrected, mySummary?.total_medium_corrected, mySummary?.total_easy_corrected],
                    barPercentage: 0.4
                },
            ]
        });
    }, [mySummary]);

    useEffect(() => {
        let URL = process.env.REACT_APP_API_URL + 'student/my-test-2';
        let URL2 = process.env.REACT_APP_API_URL + 'student/my-summary';
        let paramObj = {};
        if (defaultClass && defaultClass?.value !== 'all') {
            paramObj = { ...paramObj, class_id: defaultClass?.value };
        }
        if (defaultSubject && defaultSubject?.value !== 'all') {
            paramObj = { ...paramObj, subject_id: defaultSubject?.value };
        }
        const paramKeys = Object.keys(paramObj);
        const keyValuePairs = paramKeys.map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(paramObj[key]);
        });
        if (keyValuePairs.length) {
            URL += '?' + keyValuePairs.join('&');
            URL2 += '?' + keyValuePairs.join('&');
        }
        axios.get(URL, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setTestList(response.data.data);
        }).catch(error => {
            setTestList([]);
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });

        axios.get(URL2, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            let dataT = response.data.data;
            if (dataT.length) {
                let dataObj = dataT[0];
                let avg_time = parseInt(dataObj?.total_time) / parseInt(dataObj?.total_test);
                dataObj = { ...dataObj, avg_time: parseInt(avg_time) };
                setMySummary(dataObj);
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [defaultClass, defaultSubject, auth?.token])

    return (<React.Fragment>
        <div className={`${styles.background_Color}`}>
            <Header2 />
            <div className={`${styles.Container}`}>
                <div className={`${styles.my_DashboardMainArea}`}>
                    <div className={`${styles.my_DashboardNam}`}>
                        <p className={`${styles.my_DashboarHeading}`}>Overall Summary</p>
                        <p className={`${styles.my_DashboarSubHeading}`}>My Dashboard </p>
                    </div>
                    <div className={`${styles.my_DashboardNum}`}>
                        {/*<div className={`${styles.display_flex}`}>
                            <div className={`${styles.boardNumGroup}`}>
                                <div className={`${styles.boardNumGroup__Co}`}>
                                    <img src="/static/img/staricon.svg" alt="" />
                                </div>
                                <p className={`${styles.my_DashboardNumber}`}>355</p>
                            </div>
                            <div className={`${styles.boardNumGroup}`}>
                                <div className={`${styles.boardNumGroup__Co} ${styles.oreng_Colr}`}>
                                    <img src="/static/img/staricon.svg" alt="" />
                                </div>
                                <p className={`${styles.my_DashboardNumber}`}>52</p>
                            </div>
                        </div>*/}
                        <div className={`${styles.My_DashboardLink}`}>
                            <div className={`${styles.My_DashboardLinkGroup}`}>
                                <p className={`${styles.My_DashboardLinkGroupTex}`}>My Dashboard</p>
                                <img src="/static/img/aroicon.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.Container}`}>
                <div className={`${styles.class_SubjectMainArea}`}>
                    <p className={`${styles.class_SubjectText}`}></p>
                    <div className={`${styles.class_SubjectCusRow}`}>
                        <div className={`${styles.margin_right}`}>
                            <Select options={classDDList} placeholder={'Class'} value={defaultClass} onChange={setDefaultClass} />
                        </div>
                        <div className={`${styles.margin_right}`}>
                            <Select options={subjectDDList} placeholder={'Subject'} value={defaultSubject} onChange={setDefaultSubject} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.Container}`}>
                <div className={`${styles.total_ScoreMainSec}`}>
                    <div className={`${styles.total_ScoreCusRow}`}>
                        <div className={`${styles.total_ScoreCard}`}>
                            <div className={`${styles.total_ScoreCardUnder}`}>
                                <div className={`${styles.tex_NumGrop}`}>
                                    <p className={`${styles.total_ScoreCardUnderText}`}>Total Score</p>
                                    <p className={`${styles.total_ScoreCardUnderNumber}`}>{mySummary?.total_score}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.total_ScoreCard}`}>
                            <div className={`${styles.total_ScoreCardUnder}`}>
                                <div className={`${styles.tex_NumGrop}`}>
                                    <p className={`${styles.total_ScoreCardUnderText}`}>No Of Hard Question</p>
                                    <p className={`${styles.total_ScoreCardUnderNumber}`}>{mySummary?.total_hard_attempted}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.total_ScoreCard}`}>
                            <div className={`${styles.total_ScoreCardUnder}`}>
                                <div className={`${styles.tex_NumGrop}`}>
                                    <p className={`${styles.total_ScoreCardUnderText}`}>No Of Medium Question</p>
                                    <p className={`${styles.total_ScoreCardUnderNumber}`}>{mySummary?.total_medium_attempted}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.total_ScoreCard}`}>
                            <div className={`${styles.total_ScoreCardUnder}`}>
                                <div className={`${styles.tex_NumGrop}`}>
                                    <p className={`${styles.total_ScoreCardUnderText}`}>No Of Easy Question</p>
                                    <p className={`${styles.total_ScoreCardUnderNumber}`}>{mySummary?.total_easy_attempted}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.total_ScoreCard}`}>
                            <div className={`${styles.total_ScoreCardUnder}`}>
                                <div className={`${styles.tex_NumGrop}`}>
                                    <p className={`${styles.total_ScoreCardUnderText}`}>Avg. Time</p>
                                    <p className={`${styles.total_ScoreCardUnderNumber}`}>{(moment.duration(mySummary?.avg_time, 'seconds').hours() > 0) ? moment.duration(mySummary?.avg_time, 'seconds').hours().toString().padStart(2, '0') : '00'}:{(moment.duration(mySummary?.avg_time, 'seconds').minutes() > 0) ? moment.duration(mySummary?.avg_time, 'seconds').minutes().toString().padStart(2, '0') : '00'}:{(moment.duration(mySummary?.avg_time, 'seconds').seconds() > 0) ? moment.duration(mySummary?.avg_time, 'seconds').seconds().toString().padStart(2, '0') : '00'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.Container}`}>
                <div className={`${styles.Valu_UpDownMainSec}`}>
                    <div className={`${styles.Row}`}>
                        <div className={`${styles.Valu_UpDownBig_Sec}`}>
                            {chartdata && <div className={`${styles.Valu_UpDownBig_SecUnder}`}>
                                <Bar data={chartdata} />
                            </div>}
                            <div className={`${styles.table_Sec}`}>
                                <table>
                                    <tr>
                                        <th className={`${styles.th_HedingCustom}`}>Tests</th>
                                        <th className={`${styles.th_HedingCustom} ${styles.align_Center}`}>Score</th>
                                        <th className={`${styles.th_HedingCustom} ${styles.align_Center}`}>% of Hard Correct</th>
                                        <th className={`${styles.th_HedingCustom} ${styles.align_Center}`}>% of Medium Correct</th>
                                        <th className={`${styles.th_HedingCustom} ${styles.align_Center}`}>% of Low Correct</th>
                                        <th className={`${styles.th_HedingCustom} ${styles.align_Right}`}>Time</th>
                                    </tr>
                                    {testList.map(item => {
                                        return <tr onClick={(e) => navigate('/student/quiz-result/' + item?.quiz_id)} style={{ cursor: 'pointer' }}>
                                            <td className={`${styles.title_Vertical}`}>{item?.subject?.title}
                                                <p className={`${styles.smaLL_Ptag}`}>{item?.topic ? 'Chapter ' + item?.topic?.topic_index + ': ' + item?.topic?.title : 'All Chapter'}</p>
                                            </td>
                                            <td className={`${styles.title_Vertical} ${styles.align_Center}`}>{item?.total_marks}/{item?.total_quiz_marks}</td>
                                            <td className={`${styles.title_Vertical} ${styles.align_Center}`}>{item?.total_hard_questions ? ((item?.total_hard_corrected / item?.total_hard_questions) * 100).toFixed(1) : 0}</td>
                                            <td className={`${styles.title_Vertical} ${styles.align_Center}`}>{item?.total_medium_questions ? ((item?.total_medium_corrected / item?.total_medium_questions) * 100).toFixed(1) : 0}</td>
                                            <td className={`${styles.title_Vertical} ${styles.align_Center}`}>{item?.total_easy_questions ? ((item?.total_easy_corrected / item?.total_easy_questions) * 100).toFixed(1) : 0}</td>
                                            <td className={`${styles.title_Vertical} ${styles.align_Right} ${styles.blue_ColrTex}`}>{(moment.duration(item?.total_time, 'seconds').hours() > 0) ? moment.duration(item?.total_time, 'seconds').hours().toString().padStart(2, '0') : '00'}:{(moment.duration(item?.total_time, 'seconds').minutes() > 0) ? moment.duration(item?.total_time, 'seconds').minutes().toString().padStart(2, '0') : '00'}:{(moment.duration(item?.total_time, 'seconds').seconds() > 0) ? moment.duration(item?.total_time, 'seconds').seconds().toString().padStart(2, '0') : '00'}</td>
                                        </tr>
                                    })}
                                    {(testList.length === 0) && <tr><td colspan={6}>
                                        <div className={`${styles.noData_FoundSec}`}>
                                            <div className={`${styles.noData_Found_SecUnder}`}>
                                                <img src='/static/img/no-datafound.png' alt='' />
                                                <p>No tests are available now</p>
                                            </div>
                                        </div></td>
                                    </tr>}
                                </table>
                            </div>
                        </div>
                        <div className={`${styles.class_SubjectSmall_Sec}`}>
                            <div className={`${styles.ClassArea}`}>
                                <p className={`${styles.ClassHeding}`}>Class</p>
                                <ul className={`${styles.Class_CostUL}`}>
                                    {marksByClass.map(item => {
                                        return <li key={item._id}>
                                            <p className={`${styles.Class_CostL_TtitelCL}`}>{item.class}</p>
                                            <p className={`${styles.CostL_TtitelPG}`}>{((item.total_marks / item.total_quiz_marks) * 100).toFixed(1)}%</p>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            <div className={`${styles.SubjectArea}`}>
                                <p className={`${styles.SubjectHeding}`}>Subject</p>
                                <ul className={`${styles.Subject_CostUL}`}>
                                    {marksBySubject?.map(item => {
                                        return <li key={item._id}>
                                            <p className={`${styles.Subject_CostL_TtitelSUB_Cgreen}`}>{item.subject}</p>
                                            <p className={`${styles.CostL_TtitelPG}`}>{((item.total_marks / item.total_quiz_marks) * 100).toFixed(1)}%</p>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default withStudentAuth(MyDashboard);