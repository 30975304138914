import React, { useEffect, useState } from 'react';
import styles from './step1.module.css';
import Header2 from '../../components/header2';
import withMentorAuth from '../../components/withMentorAuth';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { authData } from '../../components/getAuth';
import { toast } from 'react-smart-toaster';
import { FormControl, MenuItem, Select } from '@mui/material';

const CreateTestStep1 = () => {
  const auth = authData();
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [boardList, setBoardList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [topicAllList, setTopicAllList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [testData, setTestData] = useState(null);

  const { handleSubmit, register, formState: { errors }, control, setValue } = useForm({
    defaultValues: {
      board_id: '',
      class_id: '',
      subject_id: '',
      level: '',
      topic_id: '',
      group_id: '',
    }
  });

  useEffect(() => {
    if (!isAddMode) {
      axios.get(process.env.REACT_APP_API_URL + 'mentor/test/' + id, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        setTestData(response.data.data);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }
  }, [isAddMode, id, auth?.token]);

  useEffect(() => {
    if (testData) {
      setValue('title', testData?.title);
      setValue('description', testData?.description);
      setValue('board_id', testData?.board_id);
      setValue('class_id', testData?.class_id);
      setValue('subject_id', testData?.subject_id);
      setValue('level', testData?.level);
      setValue('topic_id', testData?.topic_id);
      setValue('group_id', testData?.group_id);

      setSelectedBoard(testData?.board_id);
      setSelectedClass(testData?.class_id);
      setSelectedSubject(testData?.subject_id);

      if (!testData?.topic_id) {
        setValue('topic_id', "all");
      }
    }
  }, [testData, setValue]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/boards').then(response => {
      let boardListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setBoardList(boardListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/classes').then(response => {
      let classListTemp = response.data.data.map(i => { return { value: i._id, label: i.title }; });
      setClassList(classListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/subjects').then(response => {
      let subjectListTemp = response.data.data;
      setAllSubjectList(subjectListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/topics').then(response => {
      let topicListTemp = response.data.data.map(i => { return { value: i._id, topic_index: i.topic_index, label: 'Chapter ' + i.topic_index + ': ' + i.title, board_id: i?.board_id, class_id: i?.class_id, subject_id: i?.subject_id }; });
      setTopicAllList(topicListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'public/group-list').then(response => {
      let groupListTemp = response.data.data.map(i => { return { value: i._id, label: i.title + ' (' + i.classes + ')' }; });
      setGroupList(groupListTemp);
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error occured. Please try again");
      }
    });
  }, []);

  useEffect(() => {
    let subjectListTemp = allSubjectList;
    if (selectedBoard !== '') {
      subjectListTemp = subjectListTemp.filter(i => i.boards.indexOf(selectedBoard) > -1);
    }
    if (selectedClass !== '') {
      subjectListTemp = subjectListTemp.filter(i => i.classes.indexOf(selectedClass) > -1);
    }
    subjectListTemp = subjectListTemp.map(i => {
      return { value: i._id, label: i.title };
    });

    setSubjectList(subjectListTemp);
  }, [selectedBoard, selectedClass, allSubjectList]);

  useEffect(() => {
    if (selectedBoard !== '' && selectedClass !== '' && selectedSubject !== '') {
      let topicListTemp = topicAllList.sort((a, b) => a.topic_index - b.topic_index).filter(i => (i.board_id === selectedBoard && i.class_id === selectedClass && i.subject_id === selectedSubject));
      topicListTemp = [{ value: 'all', label: 'All Topics' }].concat(topicListTemp);
      setTopicList(topicListTemp);
    } else {
      setTopicList([]);
    }
  }, [selectedBoard, selectedClass, selectedSubject, topicAllList, setValue]);

  const onSubmit = (data) => {
    if (data?.group_id === '') {
      delete data.group_id;
    }
    if (data?.topic_id === 'all') {
      delete data.topic_id;
    }
    if (isAddMode) {
      axios.post(process.env.REACT_APP_API_URL + 'mentor/create-test', data, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        let testData = response.data.data;
        navigate('/mentor/create-test/step-2/' + testData?._id);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    } else {
      axios.put(process.env.REACT_APP_API_URL + 'mentor/update-test/' + id, data, {
        headers: { 'x-access-token': auth?.token }
      }).then(response => {
        navigate('/mentor/create-test/step-2/' + id);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error occured. Please try again");
        }
      });
    }

  }

  return (<React.Fragment>
    <div className={`${styles.background_Color}`}>
      <Header2 />

      <div className={`${styles.Container}`}>
        <div className={`${styles.create_AccomplishmentSec}`}>
          <p>Create test</p>
        </div>
        <div className={`${styles.FormBoxArea}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormField}`}>
              <label className={`${styles.FormLabel}`}>Name</label>
              <input type="text" placeholder="Name" className={`${styles.FormInput}`} {...register("title", { required: 'This field is required' })} />
              {errors?.title && <p className={`${styles.login_ErrorM}`}>{errors?.title?.message}</p>}
            </div>
            <div className={`${styles.FormField}`}>
              <label className={`${styles.FormLabel}`}>Description</label>
              <input type="text" placeholder="Description" className={`${styles.FormInput}`} {...register("description", { required: 'This field is required' })} />
              {errors?.description && <p className={`${styles.login_ErrorM}`}>{errors?.description?.message}</p>}
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Board</label>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      {...register('board_id', { required: 'This field is required' })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedBoard(e?.target?.value);

                            setValue('subject_id', '');
                            setValue('topic_id', '');
                          }}>
                          {boardList?.map(i => {
                            return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors?.board_id && <p className={`${styles.login_ErrorM}`}>{errors?.board_id?.message}</p>}
                </div>
              </div>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Class</label>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      {...register('class_id', { required: 'This field is required' })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedClass(e?.target?.value);

                            setValue('subject_id', '');
                            setValue('topic_id', '');
                          }}>
                          {classList?.map(i => {
                            return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors?.class_id && <p className={`${styles.login_ErrorM}`}>{errors?.class_id?.message}</p>}
                </div>
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Subject</label>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      {...register('subject_id', { required: 'This field is required' })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedSubject(e?.target?.value);

                            setValue('topic_id', '');
                          }}>
                          {subjectList?.map(i => {
                            return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors?.subject_id && <p className={`${styles.login_ErrorM}`}>{errors?.subject_id?.message}</p>}
                </div>
              </div>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Level</label>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      {...register('level', { required: 'This field is required' })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e);
                          }}>
                          <MenuItem value={'Easy'}>{'Easy'}</MenuItem>
                          <MenuItem value={'Medium'}>{'Medium'}</MenuItem>
                          <MenuItem value={'Hard'}>{'Hard'}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors?.level && <p className={`${styles.login_ErrorM}`}>{errors?.level?.message}</p>}
                </div>
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Topic</label>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      {...register('topic_id', { required: 'This field is required' })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e);
                          }}>
                          {topicList?.map(i => {
                            return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors?.topic_id && <p className={`${styles.login_ErrorM}`}>{errors?.topic_id?.message}</p>}
                </div>
              </div>
              <div className={`${styles.FormRowHalf}`}>
                <div className={`${styles.FormField}`}>
                  <label className={`${styles.FormLabel}`}>Group</label>
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      {...register('group_id')}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e);
                          }}>
                          {groupList?.map(i => {
                            return <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className={`${styles.ButtonSec}`}>
              <button type='submit' className={`${styles.SaveBU}`}>Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </React.Fragment>)
}

export default withMentorAuth(CreateTestStep1);